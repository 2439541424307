import { MutationTree } from 'vuex';
import { AuthState } from './types';
import { initialState } from './state';
import axios from 'axios';
import { Credentials } from '@/shared/model/smallPayloadModels/credentials';

import LSService from '../../../backend/LocalStorageService';

const lsService = LSService.getService();

export const mutations: MutationTree<AuthState> = {
  /**
   * Set the authentication token.
   */
  ['token']: (state: AuthState, token: string) => {
    state.token = token;
    if (token) {
      localStorage.setItem('accessToken', token);
      axios.defaults.headers.common.Authorization = token;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  },

  setAzureData(state: AuthState, payload: any) {
    if (Array.isArray(payload)) {
      state.azureData = payload[0];
    } else {
      state.azureData = payload;
    }
  },
  // setIsUserInAzureAdminGroup(state, payload: any) {
  //   state.isUserInAzureAdminGroup = payload;
  // },
  setInitialRedirect(state, payload: any) {
    state.isInitialRedirect = payload;
  },
  setUserAdminGroups(state, payload: any) {
    state.userAdminGroups = payload;
  },

  setZrNummer(state, payload: any) {
    state.zrNummer = payload;
  },
  setZrNummerModulesRoles(state, payload: any) {
    state.zrNummerWithRoles = payload;
  },
  setTestGsUserZrNummer(state, payload: any) {
    state.testGsUserZrNummer = payload;
  },
  setSupplierTooltipInfo(state, payload: any) {
    state.supplierTooltipInfo = payload;
  },
  setEnvironment(state, payload: any) {
    state.environment = payload;
  },

  setAccountId(state: AuthState, id: string) {
    state.error = false;
    state.accountId = id;
  },
  setArticlesTotal(state, payload: number) {
    state.articles.total = payload;
  },
  setArticlesIsLoading(state, payload: boolean) {
    state.articles.isLoading = payload;
  },

  setAccount(state: AuthState, payload: Credentials | undefined) {
    state.error = false;
    state.account = payload;
  },
  setToken(state: AuthState, payload: any) {
    lsService.setToken(payload);
  },
  clearTokens(state: AuthState, payload: any) {
    lsService.clearToken();
  },
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key: string) => {
      state[key] = s[key];
    });
  },
};
