export interface SaveList {
  id: string; // System.Guid
  name: string; // string
  type: string; // string
  title: string; // string
  printoption: string; // string
  warengruppen: any; // string, need any to convert comma separated values for `autocomplete` with multiple values (need array)
  modelmerker: string; // string
  aktionRef: string; // string
  zustaendig: string; // string
  modelstatus: any; // string, need `any` to convert comma separated values for `autocomplete` with multiple values (need array)

  articlesMarkerIds: number[]; //
  articlesMarkerNames: string[]; //
  modelMarkerIds: number[]; //
  modelMarkerNames: string[];
  responsibleIds: number[]; //
  responsibleNames: string[];

  vArtikelstatus: any; // string, need `any` to convert comma separated values for `autocomplete` with multiple values (need array)
  vAuspraegungStatus: any; // string, need `any` to convert comma separated values for `autocomplete` with multiple values (need array)
  sort: string; // string
  vmEgepflegt: boolean | null; // bool?
  createdAt: string; // System.DateTime
  createdOn: string; // string
}

export interface Value {
  value: SaveList[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<SaveList>): any {
  return {
    Id: data?.id || '',
    Name: data?.name || '',
    Type: data?.type || '',
    Title: data?.title || '',
    Printoption: data?.printoption || '',
    Warengruppen: data?.warengruppen?.length > 0 && data?.warengruppen[0] ? data?.warengruppen?.join(',') : '',
    Modelmerker: data?.modelmerker || '',
    AktionRef: data?.aktionRef || '',
    Zustaendig: data?.zustaendig || '',
    Modelstatus: data?.modelstatus?.length > 0 && data?.modelstatus[0] ? data?.modelstatus?.join(',') : '',

    ArticlesMarkerIds: data?.articlesMarkerIds || [],
    ModelMarkerIds: data?.modelMarkerIds || [],
    ResponsibleIds: data?.responsibleIds || [],

    VArtikelstatus: data?.vArtikelstatus?.length > 0 && data?.vArtikelstatus[0] ? data?.vArtikelstatus?.join(',') : '',
    VAuspraegungStatus:
      data?.vAuspraegungStatus?.length > 0 && data?.vAuspraegungStatus[0] ? data?.vAuspraegungStatus?.join(',') : '',
    Sort: data?.sort || '',
    VMEgepflegt: data?.vmEgepflegt !== null ? data?.vmEgepflegt : null,
    CreatedAt: data?.createdAt || new Date().toISOString,
    CreatedOn: data?.createdOn || '',
  };
}

function parse(data?: Partial<SaveList>): SaveList {
  return {
    id: data?.id || '',
    name: data?.name || '',
    type: data?.type || '',
    title: data?.title || '',
    printoption: data?.printoption || 'general',
    warengruppen: data?.warengruppen || '',
    modelmerker: data?.modelmerker || '',
    aktionRef: data?.aktionRef || '',
    zustaendig: data?.zustaendig || '',
    modelstatus: data?.modelstatus || '',

    articlesMarkerIds: data?.articlesMarkerIds || [],
    articlesMarkerNames: data?.articlesMarkerNames || [],
    modelMarkerIds: data?.modelMarkerIds || [],
    modelMarkerNames: data?.modelMarkerNames || [],
    responsibleIds: data?.responsibleIds || [],
    responsibleNames: data?.responsibleNames || [],

    vArtikelstatus: data?.vArtikelstatus || '',
    vAuspraegungStatus: data?.vAuspraegungStatus || '',
    sort: data?.sort || '',
    vmEgepflegt: data?.vmEgepflegt && data?.vmEgepflegt !== null ? data?.vmEgepflegt : null,
    createdAt: data?.createdAt || '',
    createdOn: data?.createdOn || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
