
import { MutationTree } from 'vuex';
import { UserDataState } from './types';
import Vue from 'vue';
import userData, { UserData, Value } from '@/shared/model/userData';

export const mutations: MutationTree<UserDataState> = {
  setUserDatas(state, payload: Value) {
    state.userData.items = payload.value.map((x) => userData.parse(x));
    state.userData.total = payload['@odata.count'] || 0;
    state.userData.isLoading = false;
  },
  setUserDatum(state, payload: UserData) {
    state.userDatum = payload;
  },
  setUserDatasTotal(state, payload: number) {
    state.userData.total = payload;
  },
  setUserDatasIsLoading(state, payload: boolean) {
    state.userData.isLoading = payload;
  },
};
