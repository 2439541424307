import { render, staticRenderFns } from "./status-dialog.html?vue&type=template&id=4e564ede&scoped=true&"
import script from "./status-dialog?vue&type=script&lang=ts&"
export * from "./status-dialog?vue&type=script&lang=ts&"
import style0 from "./status-dialog.scss?vue&type=style&index=0&id=4e564ede&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e564ede",
  null
  
)

/* custom blocks */
import block0 from "./status-dialog.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fzr-documents%2Fstatus-dialog%2Fstatus-dialog.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardText,VChip,VCol,VForm,VRadio,VRadioGroup,VRow,VSpacer})
