export interface ZRDocument {
  id: string; // GUID
  belegdatum: string; // System.DateTime
  belegnummer_Extern: any; // string
  kommission_Nummer: any; // string
  name_Lieferant: string; // string
  name_Empfaenger: string; // string
  status_Freigabe: number | null; // int?
  zrNummer_Empfaenger: string; // string
  zrNummer_Lieferant: any; // string
  betrag_Brutto: number;
  betrag_Netto: number;
  steursatz: number | null;
  recordID: string;
  status_Text: string;
  waehrung: string;
  bonusgutschrift: boolean | null; // bool
  skontierfaehig: boolean | null; // bool
  faelligkeit: string | null; // System.DateTime?
  text_nichtbuchen: string;
  invoices: any;

  // data from admin-panel Supplier
  neuerZRStatus: string;
  zrNummer: string;
  zrEnde: string;
  suchname2: string;
  unternehmensnameZUSATZ: string;
  zrBemerkungenzuKreditor_EXTERN: string;

}

export interface Value {
  value: ZRDocument[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ZRDocument>): any {
  return {
    Id: data?.id || '',
    belegdatum: data?.belegdatum || undefined,
    Belegnummer_Extern: data?.belegnummer_Extern || '',
    Kommission_Nummer: data?.kommission_Nummer || '',
    Name_Lieferant: data?.name_Lieferant || '',
    Name_Empfaenger: data?.name_Empfaenger || '',
    Status_Freigabe: data?.status_Freigabe || null,
    ZrNummer_Lieferant: data?.zrNummer_Lieferant || '',
    ZrNummer_Empfaenger: data?.zrNummer_Empfaenger || '',
    Betrag_Brutto: data?.betrag_Brutto || undefined,
    Betrag_Netto: data?.betrag_Netto || undefined,
    Steursatz: data?.steursatz === null || data?.steursatz === undefined ? null : data?.steursatz,
    RecordId: data?.recordID || '',
    Status_Text: data?.status_Text || '',
    Waehrung: data?.waehrung || '',
    Bonusgutschrift: data?.bonusgutschrift === null ? null : data?.bonusgutschrift,
    Skontierfaehig: data?.skontierfaehig === null ? null : data?.skontierfaehig,
    Faelligkeit: data?.faelligkeit || null,
    Text_nichtbuchen: data?.text_nichtbuchen || null,
    Invoices: data?.invoices || undefined,
  };
}

function parse(data?: Partial<ZRDocument>): ZRDocument {
  return {
    id: data?.id || '',
    belegdatum: data?.belegdatum || '',
    belegnummer_Extern: data?.belegnummer_Extern || '',
    kommission_Nummer: data?.kommission_Nummer || '',
    name_Lieferant: data?.name_Lieferant || '',
    name_Empfaenger: data?.name_Empfaenger || '',
    status_Freigabe: data?.status_Freigabe || null,
    zrNummer_Lieferant: data?.zrNummer_Lieferant || '',
    zrNummer_Empfaenger: data?.zrNummer_Empfaenger || '',
    betrag_Brutto: data?.betrag_Brutto || 0,
    betrag_Netto: data?.betrag_Netto || 0,
    steursatz: data?.steursatz === null || data?.steursatz === undefined ? null : data?.steursatz,
    recordID: data?.recordID || '',
    status_Text: data?.status_Text || '',
    waehrung: data?.waehrung || '',
    bonusgutschrift: data?.bonusgutschrift === null ? null : !!data?.bonusgutschrift,
    skontierfaehig: data?.skontierfaehig === null ? null : !!data?.skontierfaehig,
    faelligkeit: data?.faelligkeit || null,
    text_nichtbuchen: data?.text_nichtbuchen || '',
    invoices: data?.invoices || undefined,


    neuerZRStatus: data?.neuerZRStatus || '',
    zrNummer: data?.zrNummer || '',
    zrEnde: data?.zrEnde || '',
    suchname2: data?.suchname2 || '',
    unternehmensnameZUSATZ: data?.unternehmensnameZUSATZ || '',
    zrBemerkungenzuKreditor_EXTERN: data?.zrBemerkungenzuKreditor_EXTERN || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
