export default class DownloadUtils {
  public static getFileName(contentDisposition: string) {
    let fileName = contentDisposition.split(';')[2].split('=')[1].split("''")[1];
    fileName = decodeURIComponent(fileName);
    return fileName;
  }

  public static createDownloadLink = function (blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const linkElement = document.createElement('a');
    if (typeof linkElement.download === 'undefined') {
      (window as any).location = url;
      return url;
    }
    linkElement.href = url;
    linkElement.setAttribute('download', fileName);
    document.body.appendChild(linkElement);
    linkElement.click();
    linkElement.remove();
    return url;
  };

  // `window.navigator.msSaveOrOpenBlob` is deprecated and removed from Typescript (see https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1029 (search for `msSaveOrOpenBlob`))
  // so we don't need separate method for IE or old EDGE versions
}
