import store from '@/shared/store/index';
import { User } from '@/shared/model/user';
import { AUTHORITIES } from '@/shared/model/authorities';
import { msalInstance } from 'vue-msal-browser';

export default class AuthorityUtils {
  // if put in `GeneralUtils` then get next error: Uncaught TypeError: Cannot read properties of undefined (reading 'isDevEnvironment'):   at Function.getClientId (authUtils.ts:13:1)
  // seems to get this error because of using `store`
  public static isDevEnvironmentStore(): boolean {
    // console.log("store.getters['auth/environment'] :>> ", store.getters['auth/environment']);
    let result = (store.getters['auth/environment'] + '').toString().toLowerCase().includes('dev');
    console.log('xxx :>> ', result);
    return result;
  }
  public static isStagingEnvironmentStore(): boolean {
    // console.log("store.getters['auth/environment'] :>> ", store.getters['auth/environment']);
    let result = (store.getters['auth/environment'] + '').toString().toLowerCase().includes('stag');
    console.log('xxx :>> ', result);
    return result;
  }
  public static isEmptyAccount() {
    return store.getters['auth/isLoggedIn'] == false;
  }
  public static isEmptyAzureAccount() {
    return !(msalInstance && msalInstance.getAllAccounts()[0]);
  }
  public static async ensureAccountDetails() {
    if (this.isEmptyAccount()) {
      // wait for account details to be loaded
      await store.dispatch('auth/loadAccountDetails');
    }
  }
  public static getAccountInfo(): User {
    return store.getters['auth/getUser'];
  }

  public static async getApiInfo() {
    if (!store.getters['apiInfo']) {
      await store.dispatch('load_info');
    }
  }

  public static hasAnyRole(roles: string[]) {
    const user: User = store.getters['auth/account'];
    if (user.authorities == undefined) {
      return false;
    }

    roles = roles ? roles : Object.keys(AUTHORITIES);

    return user && user.authorities.some((authorities) => roles.includes(authorities));
  }

  public static isAdmin() {
    return store.getters['auth/isAdmin'];
  }

  public static isManager() {
    return store.getters['auth/isManager'];
  }
}
