
import { GetterTree } from 'vuex';
import { UserDataState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<UserDataState, RootState> = {
  ['getUserDatum']: (state) => state.userDatum,
  ['getUserData']: (state) => state.userData,
  ['getUserDataIsLoading']: (state) => state.userData?.isLoading,
  ['getUserDataTotal']: (state) => state.userData?.total,
  ['getUserDataSearchParams']: (state) => state.userData?.searchParams,
};
