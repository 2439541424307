
import { ActionTree } from 'vuex';
import { ChangeVoucherNoticeState } from './types';
import { RootState } from '../../types';
import { defaultBackendChangeVoucherNotice } from '@/shared/backend/changeVoucherNotice';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import changeVoucherNotice, { Value, ChangeVoucherNotice } from '@/shared/model/changeVoucherNotice';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.changeVoucherNotices');
export const actions: ActionTree<ChangeVoucherNoticeState, RootState> = {
  getChangeVoucherNotices({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, changeVoucherId?:number }) {
    commit('setChangeVoucherNoticesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getChangeVoucherNoticesSearchParams;
    let changeVoucherId = payload?.changeVoucherId ?? 0;
    let organisationId = '';// rootGetters[CONST.getOrganisation].id;
    return defaultBackendChangeVoucherNotice
      .getChangeVoucherNotices(searchParams, changeVoucherId)
      .then((response: AxiosResponse<Value>) => {
        commit('setChangeVoucherNotices', response.data);
        commit('setChangeVoucherNoticesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setChangeVoucherNoticesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getChangeVoucherNotice({ commit, dispatch }, id: string) {
    return defaultBackendChangeVoucherNotice
      .getChangeVoucherNotice(id)
      .then((response: AxiosResponse<ChangeVoucherNotice>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateChangeVoucherNotice({ commit, dispatch }, file: ChangeVoucherNotice) {
    return defaultBackendChangeVoucherNotice
      .updateChangeVoucherNotice(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ChangeVoucherNotice;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.changeVoucherNotice_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteChangeVoucherNotice({ commit, dispatch }, id: string) {
    return defaultBackendChangeVoucherNotice
      .deleteChangeVoucherNotice(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ChangeVoucherNotice;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
