import searchData from '@/shared/model/smallPayloadModels/searchData';
import { ZRDocumentState } from './types';
import zrDocument from '@/shared/model/zrDocument';

export const state = (): ZRDocumentState => initialState();

export const initialState = (): ZRDocumentState => ({
  searchData: searchData.defaultData(),
  initialLocalStorageSearchData: undefined,
  zrDocumentExcelIsLoading: false,
  zrDocumentPdfIsLoading: false,
  zrDocumentsRechnungsnummers: [],
  zrDocumentsStatuses: [],
  zrDocumentsMembers: [],
  zrDocumentsSuppliers: [],
  zrDocument: zrDocument.parse({}),
  zrDocuments: {
    items: [],
    isLoading: undefined,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['status_Freigabe','belegdatum'], //['belegdatum'],
        sortDesc: [false, true],//[true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
      },
      filter: '',
      orClauseFieldsIds: [
        'belegnummer_Extern',
        'kommission_Nummer',
        'zrNummer_Lieferant',
        'name_Lieferant',
        'zrNummer_Empfaenger',
        'betrag_Brutto',
        'betrag_Netto',
      ],
      andClauseFieldsIds: [],
    },
  },
});
