export interface ChangeVoucherDocument {
    id: number; // uint  
    title: string; // string?  
    text: string; // string?  
    change_voucher_id: number; // uint  
    // change_voucher: gutschriftsprozess.DataAccessPostgreSQL.Database.DataModel.ChangeVoucherDm; // gutschriftsprozess.DataAccessPostgreSQL.Database.DataModel.ChangeVoucherDm  
    bearbeit_von: string; // string?  
    bearbeit_am: string; // System.DateTime?  
    erstellt_von: string; // string?  
    erstellt_am: string; // System.DateTime?  

    document_file_id: string; // System.DateTime?  
    document_type: string; // System.DateTime?  
    document_name: string; // System.DateTime?  
    document_size: string; // System.DateTime?  
    
    isLoadingDocument: boolean; // frontend field
 
}

export interface Value {
  value: ChangeVoucherDocument[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ChangeVoucherDocument>): any {
  return {
    id: data?.id || undefined,  
    document_file_id: data?.document_file_id || undefined,  
    title: data?.title || '',  
    text: data?.text || '',  
    change_voucher_id: data?.change_voucher_id || 0,  
    //change_voucher: data?.change_voucher || '',  
    // bearbeit_von: data?.bearbeit_von || '',  
    // bearbeit_am: data?.bearbeit_am || '',  
    // erstellt_von: data?.erstellt_von || '',  
    // erstellt_am: data?.erstellt_am || '',  
  };
}

function parse(data?: Partial<ChangeVoucherDocument>): ChangeVoucherDocument {
  return {
    id: data?.id || 0,  
    document_file_id: data?.document_file_id || '',  
    title: data?.title || '',  
    text: data?.text || '',  
    change_voucher_id: data?.change_voucher_id || 0,  
    //change_voucher: data?.change_voucher || '',  
    bearbeit_von: data?.bearbeit_von || '',  
    bearbeit_am: data?.bearbeit_am || '',  
    erstellt_von: data?.erstellt_von || '',  
    erstellt_am: data?.erstellt_am || '',  
    
    document_type: data?.document_type || '',  
    document_name: data?.document_name || '',  
    document_size: data?.document_size || '',  

    
    isLoadingDocument: data?.isLoadingDocument || false,  

  };
}

export default {
  parse,
  toAPI: toAPI,
};
