import { render, staticRenderFns } from "./supplier-tooltip.html?vue&type=template&id=1b8e6a78&scoped=true&"
import script from "./supplier-tooltip.ts?vue&type=script&lang=ts&"
export * from "./supplier-tooltip.ts?vue&type=script&lang=ts&"
import style0 from "./supplier-tooltip.scss?vue&type=style&index=0&id=1b8e6a78&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b8e6a78",
  null
  
)

/* custom blocks */
import block0 from "./supplier-tooltip.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fzr-documents%2Fsearch-form%2Fsupplier-tooltip%2Fsupplier-tooltip.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports