import { LogLevel } from '@azure/msal-browser';
import GeneralUtils from './generalUtils';
import AuthUtils from './authUtils';

const tenantId = AuthUtils.getTenantId();
const clientId = AuthUtils.getClientId();

console.log(`Azure ClientId: [${clientId}], Azure TenantId: [${tenantId}]`);

const originalBrowserLocation = Object.assign({}, window.location); // keep original browser URL object (will be reset by Azure `redirectUri: '/'`)
console.log('origLocation :>> ', originalBrowserLocation);
// don't save root domain ROOT initial path ('/'), shortest used path is 5 characters (`about`)
if (
  originalBrowserLocation.pathname.length > 4 &&
  !originalBrowserLocation.pathname.includes('login') &&
  !localStorage.getItem('initialBrowserPath') // when open from PDF/Excel `initialBrowserPath` is reset to `/invoice` so cancel if rewrite if has value
) {
  let search = originalBrowserLocation.search;

  localStorage.setItem('initialBrowserPath', originalBrowserLocation.pathname + search);
}

export const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${tenantId}`, // Tenant ID of the React.JS App Registration
    clientId: `${clientId}`, // Client ID
    redirectUri: '/', // dynamic URL not working because of Azure security (probably avoid redirect to unwanted sites)
    scopes: [`api://${clientId}/api.scope`],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored // was `sessionStorage`
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge // was `false`
  },
  // graph: {
  //   url: '<your-graph-api-url>',
  //   scopes: '<your-graph-api-scopes>',
  //   response_type: "blob"
  // },
  mode: 'redirect',
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
  scopes: [`api://${clientId}/api.scope`],
};
