import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import userData, { UserData, Value } from '../model/userData';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.UserData');
export interface BackendUserData {
  getUserData(id: string): AxiosPromise<UserData>;
  getUserDatas: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteUserData(id: string): AxiosPromise;
  updateUserData(UserData: UserData): AxiosPromise<any>;
}

export const defaultBackendUserData: BackendUserData = {
  getUserData(id: string): AxiosPromise<UserData> {
    let url = `${URLS.userData}/${id}`;
    return instance.get<UserData>(url);
  },
  getUserDatas(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.userDataOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getUserDatas${url}`);
    return instance.get<Value>(url);
  },

  deleteUserData(id: string): AxiosPromise {
    logger.debug('deleteUserData');
    return instance.delete(`${URLS.userData}/${id}`);
  },
  updateUserData(UserData: any): AxiosPromise<any> {
    logger.debug('updateUserData');
    let payload = userData.toAPI(UserData);
    logger.log('updateUserData payload :>> ', payload);
    payload.SearchData = JSON.stringify(UserData.searchData);
    payload.InvoiceSearchData = JSON.stringify(UserData.invoiceSearchData);
    payload.ChangeVoucherSearchData = JSON.stringify(UserData.changeVoucherSearchData);
    payload.IsDocumentSearch = UserData.isDocumentSearch;
    payload.IsInvoiceSearch = UserData.isInvoiceSearch;
    return instance.put<UserData>(`${URLS.userData}/update`, payload);
  },
};
