import { ROUTES } from '@/router/routesEnum';
import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import zrStat, { StatusIndices as ZrStatus } from '@/shared/model/smallPayloadModels/zrStatus';
import DateField from '@/components/_common/date-field/DateField.vue';
import { CONST } from '@/shared/utils/Constants';
import { UserData } from '@/shared/model/userData';
import zrDoc, { ZRDocument } from '@/shared/model/zrDocument';

const logger = new Logger('status-dialog');
const zrDocumentModule = namespace('zrDocument');

@Component({
  name: 'status-dialog',
  components: { DateField },
})
export default class StatusDialog extends Vue {
  @Ref('observer-status-dialog-form')
  private observerExportDialogForm!: any;

  @zrDocumentModule.Action('updateZRDocumentStatus')
  private actionUpdateZRDocumentStatus!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  // zrDocument: ZRDocument = zrDoc.parse({});
  @Prop({ default: zrDoc.parse({}) })
  private zrDocument!: ZRDocument;

  isSaving = false;
  async clickSave() {
    const result = await this.observerExportDialogForm.validate();

    const payload = this.preparePayload();

    if (result && this.isFormValid) {
      this.isSaving = true;

      await this.actionUpdateZRDocumentStatus(payload)
        .then((response:any) => {
          // this.selectedStatusInEditMenu = undefined; // avoid possibility to send previous selected `status` in new dialog without selection
          this.clickClose();
          this.$emit("handle:response", response.result);
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    }
  }

  get isFormValid() {
    return this.isAllRulesValid() && !this.showStatusValidationMessage;
  }

  isAllRulesValid() {
    if (
      !this.selectedStatusInEditMenu ||
      (this.selectedStatusInEditMenu == ZrStatus.booked &&
        (this.zrDocument.bonusgutschrift === null ||
          (this.zrDocument.bonusgutschrift !== null && this.zrDocument.skontierfaehig === null))) ||
      (this.selectedStatusInEditMenu == ZrStatus.not_booked &&
        (!this.zrDocument.text_nichtbuchen || this.zrDocument.text_nichtbuchen.trim().length >100))
    ) {
      //console.warn('request not sent :>> NOT VALID ');
      return false;
    }
    if (this.selectedStatusInEditMenu == ZrStatus.not_booked && this.zrDocument.skontierfaehig !== null) {
      return false;
    }

    return true;
  }

  //#region Logic related to `edit-status` dialog
  private selectedStatusInEditMenu?: Number = 0; // need put 0 for proper reactivity and `zuBuchenSelected` getter work properly
  private showStatusValidationMessage = true;

  get zuBuchenSelected() {
    return this.selectedStatusInEditMenu && this.selectedStatusInEditMenu == ZrStatus.booked;
  }

  get nichtBuchenSelected() {
    return this.selectedStatusInEditMenu && this.selectedStatusInEditMenu == ZrStatus.not_booked;
  }

  get yesNoValues() {
    return [
      { text: this.$t('yes_value'), value: true },
      { text: this.$t('no_value'), value: false },
    ];
  }

  get statuses() {
    return {
      1: { text: this.$t('status_chip.new'), class: 'none' },
      2: { text: this.$t('status_chip.book'), class: 'green' },
      3: { text: this.$t('status_chip.not_book'), class: 'red' },
    };
  }
  get editStatuses() {
    return {
      2: { text: this.$t('status_chip.book'), class: 'green' },
      3: { text: this.$t('status_chip.not_book'), class: 'red' },
    };
  }

  private preparePayload() {
    let payload: any = {
      id: this.zrDocument.id,
      status_Freigabe: this.selectedStatusInEditMenu,
    };

    if (this.selectedStatusInEditMenu == ZrStatus.booked) {
      if (this.zrDocument.bonusgutschrift === true) {
        payload = {
          ...payload,
          bonusgutschrift: true,
          faelligkeit: this.zrDocument.faelligkeit,
          skontierfaehig: false,
        };
      } else {
        payload = {
          ...payload,
          bonusgutschrift: false,
          faelligkeit: this.zrDocument.faelligkeit,
          skontierfaehig: this.zrDocument.skontierfaehig,
        };
      }
    } else {
      payload = { ...payload, text_nichtbuchen: this.zrDocument.text_nichtbuchen };
    }

    logger.log(payload);

    return payload;
  }

  private async updateStatus(item: ZRDocument) {
    if (
      !this.selectedStatusInEditMenu ||
      (this.selectedStatusInEditMenu == ZrStatus.booked &&
        (item.bonusgutschrift === null || (item.bonusgutschrift !== null && item.skontierfaehig === null))) ||
      (this.selectedStatusInEditMenu == ZrStatus.not_booked &&
        (!item.text_nichtbuchen || item.text_nichtbuchen.trim().length > 100))
    ) {
      console.warn('request not sent :>> NOT VALID ');
      return;
    }
    let payload: any = {
      id: item.id,
      status_Freigabe: this.selectedStatusInEditMenu,
    };

    if (this.selectedStatusInEditMenu == ZrStatus.booked) {
      if (item.bonusgutschrift === true) {
        payload = { ...payload, bonusgutschrift: true, faelligkeit: item.faelligkeit, skontierfaehig: false };
      } else {
        payload = {
          ...payload,
          bonusgutschrift: false,
          faelligkeit: item.faelligkeit,
          skontierfaehig: item.skontierfaehig,
        };
      }
    } else {
      payload = { ...payload, text_nichtbuchen: item.text_nichtbuchen };
    }

    if (this.selectedStatusInEditMenu == ZrStatus.not_booked && item.skontierfaehig !== null) {
      return;
    }

    logger.log(payload);
    await this.actionUpdateZRDocumentStatus(payload)
      .then(() => {
        this.selectedStatusInEditMenu = undefined; // avoid possibility to send previous selected `status` in new dialog without selection
      })
      .catch((err: any) => {
        logger.error(err);
      });
    logger.log('updateStatus item:>> ', item);
  }

  setAbsoluteTop() {
    setTimeout(() => {
      const dialogs = document.getElementsByClassName('v-small-dialog__menu-content');
      console.log('dialogs :>> ', dialogs);
      console.log('dialogs :>> ', dialogs.length);

      console.log('dialogs :>> ', dialogs.item(0));
      const dialog = dialogs[0];
      const oldAttribute = dialog?.getAttribute('style');
      if (oldAttribute) {
        let x = oldAttribute.indexOf('top: ') + 5;
        console.log('x :>> ', x);
        let top = oldAttribute?.slice(x, oldAttribute.indexOf(';', x));
        console.log('top :>> ', top);
        const top2 = oldAttribute.substring(x, oldAttribute!.indexOf(';', x));
        console.log('top2 :>> ', top2);
        top = top.replace('px', '');

        let topNum = +top + 35;
        console.log('top new :>> ', topNum);
        dialog.setAttribute('style', `${oldAttribute}top: ${topNum}px`);
      }
      console.log('object :>> ', oldAttribute);
    }, 150);

    // this.setAbsoluteTop()
  }

  // reset previously selected data when reopen dialog for the same item(row in table) to prevent sending unwanted data
  resetInputData() {
    this.zrDocument.bonusgutschrift = null;
    this.zrDocument.skontierfaehig = null;
    this.zrDocument.text_nichtbuchen = '';
  }

  private statusChanged(value: any) {
    this.showStatusValidationMessage = false;
    this.selectedStatusInEditMenu = value;

    let twoWeeksAfterFriday = this.getNextDayOfWeek(new Date()).toISOString();
    this.zrDocument.faelligkeit = twoWeeksAfterFriday;
  }

  // `5` is Friday, `0` Sunday
  getNextDayOfWeek(date: any, dayOfWeek: any = 5) {
    var resultDateInMilliseconds = new Date(date.getTime());

    let addDays = 14;
    if (this.isTodayMondayOrTuesday()) {
      addDays = 7;
    }

    resultDateInMilliseconds.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7) + addDays);

    return resultDateInMilliseconds;
  }

  setDiscountCheckboxValue(value: any, item: any) {
    console.log('item :>> ', item);
    if (value === true) {
      this.zrDocument.skontierfaehig = false;
    } else if (value === false) {
      this.zrDocument.skontierfaehig = null;
    }
  }
  changeDate(value: any, item: any) {
    this.zrDocument.faelligkeit = value || undefined; // new Date(value).toJSON();
  }

  private isTodayMondayOrTuesday(){
    const today = new Date();
    const isMondayOrTuesday = today.getDay() === 1 || today.getDay() == 2;
    return isMondayOrTuesday;
  }

  private allowedDates(date: string) {
    const dateValue = new Date(date);
    let isFriday = dateValue.getDay() === 5;
    const today = new Date();
    let twoWeeksFromToday = new Date(today.getTime() + 12096e5 - 864e5); // `12096e5` is 14 days in milliseconds // (GSP-098) use 13 days (not 14) for 2 weeks after to be able to select again the Friday that 2 weeks from current Friday (otherwise it will be selected by default but you could not select it again manually)
    //`864e5` = 86400000 is 1 day in milliseconds

    if (this.isTodayMondayOrTuesday()) {
      twoWeeksFromToday = new Date(today.getTime() + 6048e5); // add 7 days in milliseconds to make next Friday valid (in 11 or 10 days (from Mon and Tue), not in 18 and 17 days as was before)
    }

    const isDateMoreThan2Weeks = dateValue > twoWeeksFromToday;

    return isFriday && isDateMoreThan2Weeks;
  }
  //#endregion

  clickClose() {
    this.resetInputData();
    this.$emit('click:close');
  }
}
