import { Logger } from 'fsts';
const logger = new Logger('generalUtils');

// From https://github.com/mattphillips/deep-object-diff
export const isDate = (d: any) => d instanceof Date;
export const isEmpty = (o: any) => Object.keys(o).length === 0;
export const isObject = (o: any) => o != null && typeof o === 'object';
export const properObject = (o: any) =>
  isObject(o) && !o.hasOwnProperty ? { ...o } : o;

// Returns only the values that have been changed in the updated object
const updatedDiff = (lhs: any, rhs: any) => {
  if (lhs === rhs) return {};

  if (!isObject(lhs) || !isObject(rhs)) return rhs;

  const l = properObject(lhs);
  const r = properObject(rhs);

  if (isDate(l) || isDate(r)) {
    if (l.valueOf() == r.valueOf()) return {};
    return r;
  }

  return Object.keys(r).reduce((acc, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (l.hasOwnProperty(key)) {
      const difference: any = updatedDiff(l[key], r[key]);

      if (isObject(difference) && isEmpty(difference) && !isDate(difference))
        return acc;

      return { ...acc, [key]: difference };
    }

    return acc;
  }, {});
};


function areDeeplyEqual(obj1:any, obj2:any):any {
  // fix console error
  if (obj1 === undefined && obj2 === undefined) {
    return true;
  }
  if(obj1 === undefined || obj2 === undefined) {
    return false;
  }


	//Loop through properties in object 1
	for (var p in obj1) {
		//Check property exists on both objects
		if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

		switch (typeof (obj1[p])) {
			//Deep compare objects
			case 'object':
				if (!areDeeplyEqual(obj1[p], obj2[p])) return false;
				break;
			//Compare function code
			case 'function':
				if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
				break;
			//Compare values
			default:
				if (obj1[p] != obj2[p]) return false;
		}
	}

	//Check object 2 for any extra properties
	for (var p in obj2) {
		if (typeof (obj1[p]) == 'undefined') return false;
	}
	return true;
};


const DEFAULT_DEBOUNCE_TIME = 300;

export function debounce<T extends Function>(callback: T, wait = DEFAULT_DEBOUNCE_TIME) {
  let timer = 0;
  return (...args: any) => {
    clearTimeout(timer);
    timer = window.setTimeout(() => callback(...args), wait);
  };
}

export function debounceAsync<T extends Function>(callback: T, wait = DEFAULT_DEBOUNCE_TIME) {
  return debounce(async () => await callback(), wait);
}


export default class GeneralUtils {
  public static isDevEnvironment(): boolean {
    return process.env.NODE_ENV == 'development' || window.location.host.includes('localhost');
  }
  public static isLocalDevEnv(): boolean {
    return window.location.host.toLowerCase().includes('localhost');
  }
  public static isProdEnvironment(): boolean {
    return process.env.NODE_ENV == 'production';
  }
  public static camelToSnakeCase(str: string) {
    let result = str
      .replace(/^[A-Z]/g, letter => `${letter.toLowerCase()}`)
      .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    logger.debug(`camelToSnake:${str}->${result}`);
    return result;
  }
  public static maxint = 2147483647;
  public static updatedDiff = (lhs: any, rhs: any) => {
    return updatedDiff(lhs, rhs);
  };

  public static areObjectsEqual(lhs: any, rhs: any, ignoredProperties: Array<any> = []) {
    if (ignoredProperties.length > 0) {
      this.removeProperties(lhs, ignoredProperties);
      this.removeProperties(rhs, ignoredProperties);
    }

    let areObjectsEqual = areDeeplyEqual(lhs, rhs);
    let jsonEqual = JSON.stringify(lhs) === JSON.stringify(rhs);
    // console.log('object areobj:>> ', areObjectsEqual);
    return areObjectsEqual || jsonEqual;
  }


  public static removeProperties(obj: any, properties: any){
    // no need for `return` since object passed by reference
    properties.forEach((el:any) => {
      if (obj && obj[el]) {
        delete obj[el];
      }
    });
  }



  // https://masteringjs.io/tutorials/fundamentals/wait-1-second-then
  public static delay(time: number) {
    if(time < 50) {
      time = time* 1000; // if less 50 guess it is seconds
    }
    return new Promise(resolve => setTimeout(resolve, time));
  }
}
