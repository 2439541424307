import { Logger } from 'fsts';
const logger = new Logger('localStorageUtils');

export default class LocalStorageUtils {
  public static tokenKeyName = 'azure_token';


  public static removeAzureTokenFromLs(): void {
    Object.keys(localStorage)
      .filter((x) => x.includes('login.windows.net'))
      .forEach((x) => localStorage.removeItem(x));

    window.location.reload(); // reload window
  }
  public static removeLsKeyWithExpireKey(name: string): void {
    localStorage.removeItem(name);
    localStorage.removeItem(name + '_expires');
  }

  //default 8h (in seconds): 8 * 3600
  public static setLsKeyExpireTime(name: string, expires: number | undefined = 8 * 3600) {
    let date = new Date();
    let schedule = Math.round(date.setSeconds(date.getSeconds() + expires) / 1000);

    // localStorage.setItem(name, value);
    localStorage.setItem(name + '_expires', schedule + '');
  }

  public static isLsValueExpired(name: string): boolean {
    let date = new Date();
    let currentTime = Math.round(+date / 1000);

    // Get Scheduled expire time
    let expire_time = localStorage.getItem(name + '_expires');
    if (!!expire_time == undefined || expire_time == null || expire_time == 'null') {
      expire_time = '0';
    }

    // Expired
    if (+expire_time < currentTime) {
      this.removeLsKeyWithExpireKey(name);
      return true;
    } else {
      return false;
    }
  }

  public static setAzureTokenWithExpireTime() {
    //console.log('setAzureToken :>> ', );
    let expire_time = localStorage.getItem(`${this.tokenKeyName}_expires`);
    if (!expire_time) {
      this.setLsKeyExpireTime(this.tokenKeyName);
    }

    var valueExpired = this.isLsValueExpired(this.tokenKeyName);
    // Has Data
    if (valueExpired) {
      this.removeAzureTokenFromLs();
     } else {
      // do nothing
    }
  }

}
