import { GetterTree } from 'vuex';
import { ZRDocumentState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ZRDocumentState, RootState> = {
  ['getZRDocuments']: (state) => state.zrDocuments,
  ['getZRDocumentsItems']: (state) => state.zrDocuments.items,

  ['getZRDocument']: (state) => state.zrDocument,
  ['getZRDocumentsIsLoading']: (state) => state.zrDocuments?.isLoading,
  ['getZRDocumentsTotal']: (state) => state.zrDocuments?.total,
  ['getZRDocumentsSearchParams']: (state) => state.zrDocuments?.searchParams,

  ['getZrDocumentExcelIsLoading']: (state) => state.zrDocumentExcelIsLoading,
  ['getZrDocumentPdfIsLoading']: (state) => state.zrDocumentPdfIsLoading,

  ['getZrDocumentsRechnungsnummers']: (state) => state.zrDocumentsRechnungsnummers,
  ['getZrDocumentsStatuses']: (state) => state.zrDocumentsStatuses,
  ['getZrDocumentsMembers']: (state) => state.zrDocumentsMembers,
  ['getZrDocumentsSuppliers']: (state) => state.zrDocumentsSuppliers,
  ['getSearchData']: (state) => state.searchData,
  ['getInitialLocalStorageSearchData']: (state) => state.initialLocalStorageSearchData,
  ['isSearchDataInitLocalStorageEmpty']: (state) => {
    const localStorageSearchData = state.initialLocalStorageSearchData;
    if (!localStorageSearchData) return true;

    // TODO (GSP-022): extract duplicate logic in helper class
    const searchData = JSON.parse(localStorageSearchData);
    let hasDate = !!searchData.datum;
    let hasSupplier = searchData.lieferant?.length > 0;
    let hasMembers = searchData.mitglied?.length > 0;
    let hasInvoice = searchData.rechnungsnummer?.length > 0;
    let hasStatus = searchData.status?.length > 0;

    let isEmpty = !hasDate && !hasSupplier && !hasMembers && !hasInvoice && !hasStatus;
    return isEmpty;
  },
  ['isSearchDataEmpty']: (state) => {
    const localStorageSearchData = state.initialLocalStorageSearchData;
    if (!localStorageSearchData) return true;

    const searchData = state.searchData;
    let hasDate = !!searchData.datum;
    let hasSupplier = searchData.lieferant?.length > 0;
    let hasMembers = searchData.mitglied?.length > 0;
    let hasInvoice = searchData.rechnungsnummer?.length > 0;
    let hasStatus = searchData.status?.length > 0;

    let isEmpty = !hasDate && !hasSupplier && !hasMembers && !hasInvoice && !hasStatus;
    return isEmpty;
  },
};
