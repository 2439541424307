import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

@Component({})
export default class TimeControlsService extends Vue {
  refreshing = false;
  registration: any = null;
  updateExists = false;
  // Store the SW registration so we can send it a message
  // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
  // To alert the user there is an update they need to refresh for
  updateAvailable(event: any) {
    this.registration = event.detail;
    this.updateExists = true;
    this.refreshApp(); // (GSP-141) update app automatically without dialog (and user confirmation)
  }

  // Called when the user accepts the update
  refreshApp() {
    this.updateExists = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage('SKIP_WAITING');
  }

  private mounted(): void {
    console.log('mounted');
  }

  private created(): void {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true,
    });

    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        // Here the actual reload of the page occurs
        location.reload();
      });
    }
  }
}