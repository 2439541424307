
import { GetterTree } from 'vuex';
import { SaveListState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<SaveListState, RootState> = {
  ['getSaveLists']: (state) => state.saveLists,
  ['getSaveListsIsLoading']: (state) => state.saveLists?.isLoading,
  ['getSaveListsTotal']: (state) => state.saveLists?.total,
  ['getSaveListsSearchParams']: (state) => state.saveLists?.searchParams,
};
