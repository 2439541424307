export interface FacetSearchData extends SearchData {
  searchWord: string;
  chipDataString: string;

  // need all fields from SearchData for proper filtering and correct count numbers
}

export interface SearchData {
  rechnungsnummer: string[];
  status: string[];
  mitglied: string[];
  lieferant: string[];
  datum: any; // date
  isUserInAzureAdminGroup?: boolean; // `vme-admin` or `vme-support`(see all but cannot change) groups
  zrNummer?: string;
  chipDataString?: string;
}

function defaultData(): SearchData {
  return {
    rechnungsnummer: [],
    status: [],
    mitglied: [],
    lieferant: [],
    datum: undefined,
    isUserInAzureAdminGroup: undefined,
  };
}

export default {
  defaultData,
};
