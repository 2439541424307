import { Commit } from 'vuex';

function error(text: any, commit: Commit, error?: any, logger?: any) {
  if (error && logger) {
    if (error.response?.data?.errors) {
      if (Array.isArray(error.response.data?.errors))
        logger.error(error.response.data.errors.map((x: any) => x.code + ' ' + x.description).join(', '), commit);
      else logger.error(error.response.data.errors, commit);
    } else logger.error(error);
  }
  commit('showError', text, { root: true });
}
function ok(text: any, commit: Commit, logger?: any) {
  if (logger) logger.debug(`success: ${text} [${text}]`);
  commit('showSuccess', text, { root: true });
}
export default {
  error: error,
  ok: ok,
};
