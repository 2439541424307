import i18n from '../../../i18n';

export interface ZrStatus {
  index: number;
  statusName: string;
}

export enum StatusNames {
  new = 'Neu',
  booked = 'Zu buchen',
  not_booked = 'Nicht buchen',
  rejected = 'Abgelehnt',
  //   Example: Supplier has no credit
  // Members do not receive any other feedback that a credit note has not been posted
  posted = 'Verbucht',
  // Rule information about posted credit notes.
  // To do this, the ServingLayer updates the credit notes in the module via the API.
  error = 'Fehler',
  // Credit notes that do not correspond to the schema or that may have already been posted and receive an error response from the API should be marked as technically incorrect so that the member can contact support.
}

export enum StatusNamesEn {
  new = 'New',
  booked = 'To book',
  not_booked = 'Not book',
  rejected = 'Rejected',
  //   Example: Supplier has no credit
  // Members do not receive any other feedback that a credit note has not been posted
  posted = 'Posted',
  // Rule information about posted credit notes.
  // To do this, the ServingLayer updates the credit notes in the module via the API.
  error = 'Error',
  // Credit notes that do not correspond to the schema or that may have already been posted and receive an error response from the API should be marked as technically incorrect so that the member can contact support.
}

export enum StatusIndices {
  new = 1,
  booked = 2,
  not_booked = 3,
  // (GSP-104) BELOW 3 statuses can be sent ONLY via API
  rejected = 4,
  posted = 5, // Verbucht
  error = 6,
}

export const zrStatusesList: ZrStatus[] = [
  { index: 1, statusName: i18n.locale == 'de' ? StatusNames.new : StatusNamesEn.new },
  { index: 2, statusName: i18n.locale == 'de' ? StatusNames.booked : StatusNamesEn.booked },
  { index: 3, statusName: i18n.locale == 'de' ? StatusNames.not_booked : StatusNamesEn.not_booked },
  { index: 4, statusName: i18n.locale == 'de' ? StatusNames.rejected : StatusNamesEn.rejected },
  { index: 5, statusName: i18n.locale == 'de' ? StatusNames.posted : StatusNamesEn.posted },
  { index: 6, statusName: i18n.locale == 'de' ? StatusNames.error : StatusNamesEn.error },
];

export function statusesWithLocale(backendIndices: any = []) {
  // console.log('testStatuses locale :>> ', StatusNames[StatusNames.new]);
  // console.log('StatusNames.new :>> ', StatusNames.new);
  // console.log('StatusNames.new :>> ', StatusNames.new);
  // console.log('i18n.tc(`status_chip.${StatusNames.new}`) :>> ', i18n.tc(`status_chip.${StatusNames.new}`));
  // console.log('i18n.te(`status_chip.${StatusNames.new}`) :>> ', i18n.te(`status_chip.${StatusNames.new}`));
  // console.log('i18n.t(`status_chip.${StatusNames.new}`) :>> ', i18n.t(`status_chip.${StatusNames.new}`));

  let array = [
    { index: 1, statusName: i18n.tc(`status_chip.new`) },
    { index: 2, statusName: i18n.tc(`status_chip.book`) },
    { index: 3, statusName: i18n.tc(`status_chip.not_book`) },
    { index: 4, statusName: i18n.tc(`status_chip.rejected`) },
    { index: 5, statusName: i18n.tc(`status_chip.posted`) },
    { index: 6, statusName: i18n.tc(`status_chip.error`) },
  ];
  if (backendIndices.length > 0) {
    array = array.filter((x) => backendIndices.includes(x.index));
  }

  return array;
}

// let array = [
//   { index: 1, statusName: i18n.te(`status_chip.new`) },
//   // { index: 1, statusName: locale == 'de' ? StatusNames.new : StatusNamesEn.new },
//   { index: 2, statusName: locale == 'de' ? StatusNames.booked : StatusNamesEn.booked },
//   { index: 3, statusName: locale == 'de' ? StatusNames.not_booked : StatusNamesEn.not_booked },
//   { index: 4, statusName: locale == 'de' ? StatusNames.rejected : StatusNamesEn.rejected },
//   { index: 5, statusName: locale == 'de' ? StatusNames.posted : StatusNamesEn.posted },
//   { index: 6, statusName: locale == 'de' ? StatusNames.error : StatusNamesEn.error },
// ];

export default {
  zrStatusesList,
};
