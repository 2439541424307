
import { MutationTree } from 'vuex';
import { ChangeVoucherNoticeState } from './types';
import Vue from 'vue';
import changeVoucherNotice, { ChangeVoucherNotice, Value } from '@/shared/model/changeVoucherNotice';

export const mutations: MutationTree<ChangeVoucherNoticeState> = {
  setChangeVoucherNotices(state, payload: Value) {
    state.changeVoucherNotices.items = payload.value.map((x) => changeVoucherNotice.parse(x));
    state.changeVoucherNotices.total = payload['@odata.count'] || 0;
    state.changeVoucherNotices.isLoading = false;
  },
  setChangeVoucherNoticesTotal(state, payload: number) {
    state.changeVoucherNotices.total = payload;
  },
  setChangeVoucherNoticesIsLoading(state, payload: boolean) {
    state.changeVoucherNotices.isLoading = payload;
  },
};
