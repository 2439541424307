
import { GetterTree } from 'vuex';
import { ChangeVoucherDocumentState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ChangeVoucherDocumentState, RootState> = {
  ['getChangeVoucherDocuments']: (state) => state.changeVoucherDocuments,
  ['getChangeVoucherDocumentsIsLoading']: (state) => state.changeVoucherDocuments?.isLoading,
  ['getChangeVoucherDocumentsTotal']: (state) => state.changeVoucherDocuments?.total,
  ['getChangeVoucherDocumentsSearchParams']: (state) => state.changeVoucherDocuments?.searchParams,
};
