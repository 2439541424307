import GeneralUtils from './generalUtils';

export default class AuthUtils {
  public static getTenantId() {
    let tenantId =
      GeneralUtils.isDevEnvironment() || isUrlInclude('dev4you')
        ?  GeneralUtils.isLocalDevEnv() ? '9d3a2809-e0a2-4cfd-b2ce-bd540b6da009' : '2775340b-b251-401c-bcac-e05e5f499a3b'
        : 'f606a097-2d4d-4038-97f3-4287834b31bf'; // Azure tenantId is the same both for multiple `clienId`
    return tenantId;
  }

  public static getClientId() {
    let clientId =
      GeneralUtils.isDevEnvironment() || isUrlInclude('dev4you')
        ? GeneralUtils.isLocalDevEnv() ? '591cf799-7461-4f0b-bc5f-5044e5684126' : '5ee51967-6ecb-4bea-b004-dbd1b766afe5'
        : isUrlInclude('piex-prod.meinvme.de') || isUrlInclude('gutschriftsprozess.meinvme.de')
            ? 'c1e65296-c7c6-4662-93c2-0771038c4ec5' // Old `Development` enviroment on @Chaslau server, not sure we need it from 2024-09
            : isUrlInclude('staging.zr-gs.meinvme') ?  '4c00701c-1a4d-4e4e-b373-e3054b6f98f4'
                : isUrlInclude('prod.zr-gs.meinvme.de') ? '20e85b81-d6aa-46f1-88e4-8c3c845b4b11' : 'NO ID' ;
                //        `10210597-b03a-461a-8082-f0853a78b5b2` for Staging env (staging.zr-gs.meinvme.de until 2024-09)

    // from 2024-09 new ClientId for Staging/Production
    // (Staging) Client-ID: 4c00701c-1a4d-4e4e-b373-e3054b6f98f4 URL: https://staging.zr-gs.meinvme.de/
    // (Production) Client-ID: 20e85b81-d6aa-46f1-88e4-8c3c845b4b11 URL: https://prod.zr-gs.meinvme.de/

    return clientId;
  }

}

function isUrlInclude(value: string): boolean {
  let result = window.location.host.toLowerCase().includes(value);
  return result;
}
