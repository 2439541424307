import { MutationTree } from 'vuex';
import { ZRDocumentState } from './types';
import Vue from 'vue';
import zrDocument, { ZRDocument, Value } from '@/shared/model/zrDocument';
import { statusesWithLocale, zrStatusesList } from '@/shared/model/smallPayloadModels/zrStatus';
import i18n from '@/i18n';

export const mutations: MutationTree<ZRDocumentState> = {
  setZRDocuments(state, payload: Value) {
    state.zrDocuments.items = payload.value.map((x) => zrDocument.parse(x));
    state.zrDocuments.total = payload['@odata.count'] || 0;
    state.zrDocuments.isLoading = false;
  },
  setZRDocumentsTotal(state, payload: number) {
    state.zrDocuments.total = payload;
  },
  setZRDocumentsIsLoading(state, payload: boolean) {
    state.zrDocuments.isLoading = payload;
  },

  setZRDocument(state, payload: ZRDocument) {
    state.zrDocument = payload;
  },

  setZrDocumentStatusInList(state, payload: any) {
    let editedDoc = state.zrDocuments.items.find((zr) => zr.id == payload.id);
    if (editedDoc) {
      editedDoc!.status_Freigabe = payload.status_Freigabe;
    }
  },
  setZrDocumentsRechnungsnummers(state, payload: any) {
    state.zrDocumentsRechnungsnummers = payload;
  },
  setZrDocumentsStatuses(state, payload: any) {
    let result = statusesWithLocale().filter((st) => payload.includes(st.index));
    console.log('result :>> ', result);
    // if (result.length == 0) {
    //   result = zrStatusesList;
    // }
    // console.log('result2 :>> ', result);
    state.zrDocumentsStatuses = result;
    console.log('state.zrDocumentsStatuses :>> ', state.zrDocumentsStatuses);
  },
  setZrDocumentsMembers(state, payload: any) {
    state.zrDocumentsMembers = payload;
  },
  setZrDocumentsSuppliers(state, payload: any) {
    state.zrDocumentsSuppliers = payload;
  },

  setZrDocumentPdfIsLoading(state, payload: any) {
    state.zrDocumentPdfIsLoading = payload;
  },
  setZrDocumentExcelIsLoading(state, payload: any) {
    state.zrDocumentExcelIsLoading = payload;
  },

  setSearchData(state, payload: any) {
    state.searchData = payload;
  },
  setInitialLocalStorageSearchData(state, payload: any) {
    state.initialLocalStorageSearchData = payload;
  },
  resetSearchFormData(state, payload: any) {
    state.searchData.rechnungsnummer = [];
    state.searchData.status = [];
    state.searchData.mitglied = [];
    state.searchData.lieferant = [];
    state.searchData.datum = undefined;
  },
};
