import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import saveList, { SaveList, Value } from '../model/saveList';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.SaveList');
export interface BackendSaveList {
  getSaveList(id: string): AxiosPromise<SaveList>;
  getSaveLists: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteSaveList(id: string): AxiosPromise;
  updateSaveList(SaveList: SaveList): AxiosPromise<any>;
}

export const defaultBackendSaveList: BackendSaveList = {
  getSaveList(id: string): AxiosPromise<SaveList> {
    let url = `${URLS.saveListOdata}/${id}`;
    return instance.get<SaveList>(url);
  },
  getSaveLists(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.saveListOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getSaveLists${url}`);
    return instance.get<Value>(url);
  },

  deleteSaveList(id: string): AxiosPromise {
    logger.debug('deleteSaveList');
    return instance.delete(`${URLS.saveList}/${id}`);
  },
  updateSaveList(SaveList: SaveList): AxiosPromise<any> {
    logger.debug('updateSaveList');
    return instance.put<SaveList>(`${URLS.saveList}/update`, saveList.toAPI(SaveList));
  },
};
