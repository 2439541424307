import { MutationTree } from 'vuex';
import { ChangeVoucherState } from './types';
import Vue from 'vue';
import changeVoucher, { ChangeVoucher, Value } from '@/shared/model/changeVoucher';
import { voucherStatusesWithLocale } from '@/shared/model/smallPayloadModels/changevoucherStatusType';
import { voucherAeaTypeWithLocale } from '@/shared/model/smallPayloadModels/changevoucherType';
import { voucherIntStatusesWithLocale } from '@/shared/model/smallPayloadModels/changevoucherInternalStatus';
export const mutations: MutationTree<ChangeVoucherState> = {
  setChangeVouchers(state, payload: Value) {
    state.changeVouchers.items = payload.value.map((x) => changeVoucher.parse(x));
    state.changeVouchers.total = payload['@odata.count'] || 0;
    state.changeVouchers.isLoading = false;
  },
  setChangeVouchersTotal(state, payload: number) {
    state.changeVouchers.total = payload;
  },
  setChangeVouchersIsLoading(state, payload: boolean) {
    state.changeVouchers.isLoading = payload;
  },
  setChangeVoucher(state, payload: ChangeVoucher) {
    state.changeVoucher = payload;
  },
  setChangeVoucherMinMaxBruttoNetto(state, payload: any) {
    state.changeVoucherMinMaxBruttoNetto = payload;
  },
  setChangeVoucherAeaTyps(state, payload: any) {
    let result =voucherAeaTypeWithLocale().filter((st) => payload.includes(st.index));
    state.changeVoucherAeaTyps = result;
  },
  setChangeVoucherStatusTypes(state, payload: any) {
    let result = voucherStatusesWithLocale().filter((st) => payload.includes(st.index));
    state.changeVoucherStatusTypes = result;
  },
  setChangeVoucherIntStatusTypes(state, payload: any) {
    let result = voucherIntStatusesWithLocale().filter((st) => payload.includes(st.index));
    state.changeVoucherIntStatusTypes = result;
  },
  setChangeVoucherMembers(state, payload: any) {
    state.changeVoucherMembers = payload;
  },
  setChangeVoucherSuppliers(state, payload: any) {
    state.changeVoucherSuppliers = payload;
  },
  setChangeVoucherSearchData(state, payload: any) {
    state.changeVoucherSearchData = payload;
  },
  setInitialLocalStorageChangeVoucherSearchData(state, payload: any) {
    state.initialLocalStorageChangeVoucherSearchData = payload;
  },
};
