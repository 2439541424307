export interface ChangeVoucher {
  id: string; // ulong
  nd_id: string; // string
  process_Id: string; // string?
  aae_typ: number; // byte
  change_reason?: number; // byte
  status: number; // byte
  int_status: number; // byte
  wv_date: string; // System.DateTime
  document_number: string; // string?
  commission_number: string; // string?
  original: number; // byte?
  external_document_reference: string; // string?
  erfassungs_datum: string; // System.DateTime
  processing_date: string; // System.DateTime?
  due_date: string; // System.DateTime?
  net_amount: number | undefined; // int
  gross_amount: number | undefined; // int
  currency: string; // string?
  tax_rate: number | undefined; // sbyte?
  tax_amount: number; // int
  cash_discount_rate: number; // sbyte
  cash_discount_period: number; // int
  discount_rate: number; // sbyte
  discount_amount: number; // int
  total_discount_rate: number; // sbyte
  receiver_number: string; // string
  name_mitglied: string; // string
  sender_number: string; // string
  name_lieferant: string; // string?
  total_discount_rate_info: string; // string?
  attachment: string; // string
  end_of_week_date: string; // System.DateTime?
  updated: string; // System.DateTime
  bearbeit_von: string; // string?
  bearbeit_am: string; // System.DateTime?
  erstellt_von: string; // string?
  erstellt_am: string; // System.DateTime?

  cv_skonto: boolean;
  cv_skontosatz: number | null; // uint?
  cv_belegnr: string; // string?
  cv_text: string; // string?

  aae_typ_id: string; // pure frontend

  dms_nr_invoice: string; // from backend join with `Invoice`

  // data from admin-panel Supplier
  neuerZRStatus: string;
  zrNummer: string;
  zrEnde: string;
  suchname2: string;
  unternehmensnameZUSATZ: string;
  zrBemerkungenzuKreditor_EXTERN: string;
  supplierName: string;
}

export interface Value {
  value: ChangeVoucher[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ChangeVoucher>): any {
  return {
    id: data?.id || undefined,
    nd_id: data?.nd_id || '',
    process_Id: data?.process_Id || '',
    aae_typ: data?.aae_typ || '',
    change_reason: data?.change_reason || 0,
    status: data?.status || 0,
    int_status: data?.int_status || 0,
    wv_date: data?.wv_date || undefined,

    document_number: data?.document_number || '',
    commission_number: data?.commission_number || '',
    original: data?.original || 0,
    external_document_reference: data?.external_document_reference || '',
    erfassungs_datum: data?.erfassungs_datum || '',
    processing_date: data?.processing_date || undefined,
    due_date: data?.due_date || undefined,
    net_amount: data?.net_amount || 0,
    gross_amount: data?.gross_amount || 0,
    currency: data?.currency || '',
    tax_rate: data?.tax_rate || 0,
    tax_amount: data?.tax_amount || 0,
    cash_discount_rate: data?.cash_discount_rate || 0,
    cash_discount_period: data?.cash_discount_period || 0,
    discount_rate: data?.discount_rate || 0,
    discount_amount: data?.discount_amount || 0,
    total_discount_rate: data?.total_discount_rate || 0,
    receiver_number: data?.receiver_number || '',
    name_mitglied: data?.name_mitglied || '',
    sender_number: data?.sender_number || '',
    name_lieferant: data?.name_lieferant || '',
    total_discount_rate_info: data?.total_discount_rate_info || '',
    attachment: data?.attachment || '',
    end_of_week_date: data?.end_of_week_date || undefined,
    updated: data?.updated || undefined,
    bearbeit_von: data?.bearbeit_von || '',
    bearbeit_am: data?.bearbeit_am || undefined,
    erstellt_von: data?.erstellt_von || '',
    erstellt_am: data?.erstellt_am || undefined,

    
    dms_nr_invoice: data?.dms_nr_invoice || '',
    
    cv_skonto: data?.cv_skonto || false,
    cv_skontosatz: data?.cv_skontosatz === null || data?.cv_skontosatz === undefined ? null : data?.cv_skontosatz,
    cv_belegnr: data?.cv_belegnr || undefined,
    cv_text: data?.cv_text || undefined,
  };
}

function parse(data?: Partial<ChangeVoucher>): ChangeVoucher {
  return {
    id: data?.id || '',
    nd_id: data?.nd_id || '',
    process_Id: data?.process_Id || '',
    aae_typ: data?.aae_typ || 0,
    change_reason: data?.change_reason && data?.change_reason >= 0 ? data?.change_reason : undefined,
    status: data?.status || 0,
    int_status: data?.int_status || 0,
    wv_date: data?.wv_date || '',

    document_number: data?.document_number || '',
    commission_number: data?.commission_number || '',
    original: data?.original || 0,
    external_document_reference: data?.external_document_reference || '',
    erfassungs_datum: data?.erfassungs_datum || new Date().toISOString(),
    processing_date: data?.processing_date || '',
    due_date: data?.due_date || '',
    net_amount: data?.net_amount || 0,
    gross_amount: data?.gross_amount || 0,
    currency: data?.currency || 'EUR',
    tax_rate: data?.tax_rate || undefined,
    tax_amount: data?.tax_amount || 0,
    cash_discount_rate: data?.cash_discount_rate || 0,
    cash_discount_period: data?.cash_discount_period || 0,
    discount_rate: data?.discount_rate || 0,
    discount_amount: data?.discount_amount || 0,
    total_discount_rate: data?.total_discount_rate || 0,
    receiver_number: data?.receiver_number || '',
    name_mitglied: data?.name_mitglied || '',
    sender_number: data?.sender_number || '',
    name_lieferant: data?.name_lieferant || '',
    total_discount_rate_info: data?.total_discount_rate_info || '',
    attachment: data?.attachment || '',
    end_of_week_date: data?.end_of_week_date || '',
    updated: data?.updated || '',
    bearbeit_von: data?.bearbeit_von || '',
    bearbeit_am: data?.bearbeit_am || '',
    erstellt_von: data?.erstellt_von || '',
    erstellt_am: data?.erstellt_am || '',

    cv_skonto: data?.cv_skonto || false,
    cv_skontosatz: data?.cv_skontosatz === null || data?.cv_skontosatz === undefined ? null : data?.cv_skontosatz,
    cv_belegnr: data?.cv_belegnr || '',
    cv_text: data?.cv_text || '',

    aae_typ_id: data?.aae_typ_id || '', // pure frontend

    dms_nr_invoice: data?.dms_nr_invoice || '',

    // from admin-panel
    neuerZRStatus: data?.neuerZRStatus || '',
    zrNummer: data?.zrNummer || '',
    zrEnde: data?.zrEnde || '',
    suchname2: data?.suchname2 || '',
    unternehmensnameZUSATZ: data?.unternehmensnameZUSATZ || '',
    zrBemerkungenzuKreditor_EXTERN: data?.zrBemerkungenzuKreditor_EXTERN || '',
    supplierName: data?.supplierName || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
