import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email, confirmed, regex, min, max, ext, min_value, size } from 'vee-validate/dist/rules';
import validationMessagesDE from 'vee-validate/dist/locale/de';
import validationMessagesEN from 'vee-validate/dist/locale/en';
import i18n from '../i18n';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

localize('en', validationMessagesEN);
localize('de', validationMessagesDE);
localize(i18n.messages); // enable custom messages (merge in locale), otherwise will see just "{Field} ist ungültig."

// built-in validators (in Vee-validate)
extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
extend('regex', regex);
extend('min', min);
extend('max', max);
extend('ext', ext);
extend('size', size);
extend('min_value', min_value);
