var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (true)?_c('div',{staticClass:"zr-documents__component",attrs:{"width":"400px"}},[(_vm.isUserLoggedInAndHasRights && !_vm.initialRedirect && _vm.documentsLoaded)?_c('v-card',[_c('v-form',[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"text-h5 Anthrazit--text"},[_vm._v(_vm._s(_vm.$t('title')))])]),_c('v-card-text',[_c('d4y-table',{attrs:{"loadingPdf":_vm.getZrDocumentPdfIsLoading,"loadingExcel":_vm.getZrDocumentExcelIsLoading,"haveMoreActions":true,"searchLabel":_vm.$t('table_search_label'),"mitgliedList":_vm.mitgliedList,"emptyMitgliedListForGsUser":_vm.isEmptyMitgliedListForGsUser,"tableHasFilterBtn":false,"tableHeaderText":_vm.tableText,"hasFooterBtn":false,"isFooterBtnDisabled":_vm.isHasSearchData,"loading":_vm.zrDocumentsIsLoading,"loadingFacet":_vm.loadingFacet,"serverItemsLength":_vm.getZRDocuments.total,"items":_vm.items,"headers":_vm.headers,"options":_vm.zrDocumentsSearchParams.dataOption,"isSearchDataEmpty":_vm.isAllFiltersEmpty,"searchMenuObj":_vm.searchMenuObj},on:{"update:options":_vm.selectionUpdateOptions,"click:clear-all-filter":_vm.clearAllFilters,"excel:download":_vm.excelDownload,"pdf:download":_vm.pdfDownload,"search:chip":_vm.searchInRealObjects,"remove:chip":_vm.removeChip},scopedSlots:_vm._u([{key:"belegdatum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.belegdatum,_vm.$i18n.locale))+" ")]}},{key:"belegnummer_Extern",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue--text text-decoration-underline cursor_pointer",on:{"click":function($event){$event.stopPropagation();return _vm.openZrDocument(item)}}},[_vm._v(_vm._s(item.belegnummer_Extern))])]}},{key:"betrag_Brutto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.betrag_Brutto.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + _vm.getCurrencySymbol(item.waehrung.toLowerCase()))+" ")]}},{key:"betrag_Netto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.betrag_Netto.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + _vm.getCurrencySymbol(item.waehrung.toLowerCase()))+" ")]}},{key:"zrNummer_Lieferant",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'error--text': item.neuerZRStatus && item.neuerZRStatus !== 'aktiv'}},[_vm._v(_vm._s(item.zrNummer_Lieferant)+" ")]),(item.zrNummer)?_c('v-tooltip',{attrs:{"color":_vm.supplierTooltipInfo && item.zrNummer ? '#909090' : 'transparent',"left":"","content-class":"custom-supplier-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"primary","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',{staticClass:"d-block",staticStyle:{"width":"400px"}},[_c('div',{staticStyle:{"color":"white","text-align":"left","width":"350px"}},[(item.zrNummer)?_c('supplier-tooltip',{attrs:{"tooltipInfo":item}}):_vm._e()],1)])]):_vm._e()]}},{key:"type",fn:function(ref){
var item = ref.item;
return [(item.status_Freigabe && (_vm.isUserSupport || item.status_Freigabe != 1))?_c('v-chip',{staticClass:"status__chip",class:_vm.statuses[item.status_Freigabe].class},[_vm._v(_vm._s(_vm.statuses[item.status_Freigabe].text)+" "),(item.status_Text && _vm.apiStatuses.includes(item.status_Freigabe))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status_Text))])]):_vm._e()],1):_vm._e(),( item.status_Freigabe && item.status_Freigabe == 1 && !_vm.isUserSupport)?_c('v-chip',{staticClass:"status__chip cursor_pointer",class:_vm.statuses[item.status_Freigabe].class,on:{"click":function($event){return _vm.onShowMenu($event,item)}}},[_vm._v(_vm._s(_vm.statuses[item.status_Freigabe].text))]):_vm._e()]}},{key:"more-actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"size":"28"},on:{"click":function($event){$event.stopPropagation();return _vm.openZrDocument(item.item)}}},[_vm._v("mdi-square-edit-outline")])]}}],null,false,1086661766)})],1)],1)],1):(_vm.isUserLoggedInAndHasRights === false && (_vm.zrNummer === null || !_vm.isUserHasRightsForDocuments) && !_vm.initialRedirect && _vm.documentsLoaded)?_c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2","max-width":"600"}},[_c('v-card-title',[_c('span',{staticClass:"text-h4 font-weight-light"},[_vm._v(_vm._s(_vm.$t('no_access_title')))])]),_c('v-card-text',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('no_access_text'))+" ("),_c('a',{staticClass:"primary--text",attrs:{"href":"mailto:support@meinvme.de"}},[_vm._v("support@meinvme.de")]),_vm._v(") ")])],1)],1):_c('div',[_c('v-progress-circular',{attrs:{"size":70,"width":7,"indeterminate":"","color":"primary"}})],1),(_vm.showMenu)?_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":"","bottom":"","close-on-content-click":false},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('status-dialog',{attrs:{"zrDocument":_vm.currentItem},on:{"click:close":_vm.closeMenu}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }