import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import invSearchData, { InvoiceSearchData } from '@/shared/model/smallPayloadModels/invoiceSearchData';
import cngvoucherSearchData, { ChangeVoucherSearchData } from '@/shared/model/smallPayloadModels/changeVoucherSearchData';
import DateField from '@/components/_common/date-field/DateField.vue';
import { changevoucherTypeList, voucherAeaTypeWithLocale } from '@/shared/model/smallPayloadModels/changevoucherType';
import { changevoucherStatusTypeList, voucherStatusesWithLocale } from '@/shared/model/smallPayloadModels/changevoucherStatusType';
import { changevoucherInternalStatusList, voucherIntStatusesWithLocale } from '@/shared/model/smallPayloadModels/changevoucherInternalStatus';
import SupplierTooltip from '../../zr-documents/search-form/supplier-tooltip/supplier-tooltip.vue';
import { documentTypeList } from '@/shared/model/smallPayloadModels/documentType';

const logger = new Logger('search-form-voucher');
const invoiceModule = namespace('invoice');
const changeVoucherModule = namespace('changeVoucher');
const authModule = namespace('auth');

@Component({
  components: { DateField, SupplierTooltip, },
})
export default class SearchFormVoucher extends Vue {
  @Ref('aeaTypeCombobox')
  private refAeaTypeCombobox!: any;
  @Ref('documentTypeCombobox')
  private refDocumentTypeCombobox!: any;
  @Ref('intStatusTypeCombobox')
  private refintStatusTypeCombobox!: any;
  
  @Ref('membersCombobox')
  private refMembersCombobox!: any;
  @Ref('suppliersCombobox')
  private refSuppliersCombobox!: any;

  @changeVoucherModule.Action('getChangeVoucherMinMaxBruttoNetto')
  private actionGetChangeVoucherMinMaxBruttoNetto!: any;
  @changeVoucherModule.Getter('getChangeVoucherMinMaxBruttoNetto')
  private getChangeVoucherMinMaxBruttoNetto!: any;

  @changeVoucherModule.Action('getChangeVoucherStatusTypes')
  private actionGetChangeVoucherStatusTypes!: any;
  @changeVoucherModule.Getter('getChangeVoucherStatusTypes')
  private getChangeVoucherStatusTypes!: any;

  @changeVoucherModule.Action('getChangeVoucherIntStatusTypes')
  private actionGetChangeVoucherIntStatusTypes!: any;
  @changeVoucherModule.Getter('getChangeVoucherIntStatusTypes')
  private getChangeVoucherIntStatusTypes!: any;

  @changeVoucherModule.Action('getChangeVoucherAeaTyps')
  private actionGetChangeVoucherAeaTyps!: any;
  @changeVoucherModule.Getter('getChangeVoucherAeaTyps')
  private getChangeVoucherAeaTyps!: any;

  @invoiceModule.Action('getZrDocumentsStatuses')
  private actionGetZrDocumentsStatuses!: any;
  @invoiceModule.Getter('getZrDocumentsStatuses')
  private getZrDocumentsStatuses!: any;

  @invoiceModule.Action('getInvoiceMembers')
  private actionGetInvoiceMembers!: any;
  @invoiceModule.Getter('getInvoiceMembers')
  private getInvoiceMembers!: any;

  @changeVoucherModule.Action('getChangeVoucherMembers')
  private actionGetChangeVoucherMembers!: any;
  @changeVoucherModule.Getter('getChangeVoucherMembers')
  private getChangeVoucherMembers!: any;

  @invoiceModule.Action('getInvoiceSuppliers')
  private actionGetInvoiceSuppliers!: any;
  @invoiceModule.Action('updateInvoiceSuppliers')
  private actionUpdateInvoiceSuppliers!: any;
  @invoiceModule.Getter('getInvoiceSuppliers')
  private getInvoiceSuppliers!: any;

  @changeVoucherModule.Action('getChangeVoucherSuppliers')
  private actionGetChangeVoucherSuppliers!: any;
  @changeVoucherModule.Action('updateChangeVoucherSuppliers')
  private actionUpdateChangeVoucherSuppliers!: any;
  @changeVoucherModule.Getter('getChangeVoucherSuppliers')
  private getChangeVoucherSuppliers!: any;

  @invoiceModule.Action('resetInvoiceSearchFormData')
  private actionResetInvoiceSearchFormData!: any;

  @invoiceModule.Action('updateInvoiceSearchData')
  private actionUpdateInvoiceSearchData!: any;

  @changeVoucherModule.Action('updateChangeVoucherSearchData')
  private actionUpdateChangeVoucherSearchData!: any;

  @invoiceModule.Action('updateInitialLocalStorageInvoiceSearchData')
  private actionUpdateInitialLocalStorageInvoiceSearchData!: any;
  @invoiceModule.Getter('isInvoiceSearchDataInitLocalStorageEmpty')
  private isInvoiceSearchDataInitLocalStorageEmpty!: any;
  @invoiceModule.Getter('isInvoiceSearchDataEmpty')
  private isInvoiceSearchDataEmpty!: any;

  @changeVoucherModule.Action('updateInitialLocalStorageChangeVoucherSearchData')
  private actionUpdateInitialLocalStorageChangeVoucherSearchData!: any;
  @changeVoucherModule.Getter('getChangeVouchersSearchParams')
  private changeVouchersSearchParams!: any;

  @changeVoucherModule.Getter('isChangeVoucherSearchDataInitLocalStorageEmpty')
  private isChangeVoucherSearchDataInitLocalStorageEmpty!: any;
  @changeVoucherModule.Getter('isChangeVoucherSearchDataEmpty')
  private isChangeVoucherSearchDataEmpty!: any;  

  @changeVoucherModule.Getter('getChangeVoucherSearchData')
  private getChangeVoucherSearchData!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('supplierTooltipInfo')
  private supplierTooltipInfo!: any;

  private isOpenedMenuNettoMinMax: boolean = false;
  private isOpenedMenuBruttoMinMax: boolean = false;

  get isSingleZrNummer() {
    return !!this.zrNummer && !this.zrNummer.includes(',');
  }
 
  created() {
    if (!this.getChangeVoucherSearchData) {
      this.getChangeVoucherSearchData = cngvoucherSearchData.defaultData(); // `{}` returned  error
    }
  }

  async mounted() {
    var promiseAll = [];

    if (this.isUserInAzureAdminGroupGetter || (!this.isUserInAzureAdminGroupGetter && !this.isSingleZrNummer)) {
      promiseAll.push(this.getMembers());
    }
    
    if (this.isUserInAzureAdminGroupGetter || !(!this.isUserInAzureAdminGroupGetter && this.zrNummer == '-')) {
      promiseAll.push(this.getAeaTyps(), this.getDocumentTypes(), this.getIntStatusTypes(), this.getSuppliers(), this.getMinMaxBruttoNetto());
      await Promise.all(promiseAll);
    }

    const localStorageChangeVoucherSearchData = localStorage.getItem('changeVoucherSearchData');

    if (!!localStorageChangeVoucherSearchData) {
      this.actionUpdateInitialLocalStorageChangeVoucherSearchData(localStorageChangeVoucherSearchData);
    }
    if (localStorageChangeVoucherSearchData && !this.isChangeVoucherSearchDataInitLocalStorageEmpty) {
      this.changevoucherSearchData = JSON.parse(localStorage.getItem('changeVoucherSearchData')!);
    }
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? (this.zrNummerWithRoles.voucherZrNummer + '') || '-' : this.zrNummer + '';
    return result;
  }

  isChangeVoucherearchDataWasCleared = false;
  @Watch('isChangeVoucherSearchDataEmpty', { deep: true })
  public async onIsChangeVoucherSearchDataEmpty(newVal: boolean, oldVal: boolean) {
    if (newVal == true) {
      this.isChangeVoucherearchDataWasCleared = true; // avoid eternal loop on clearing `invoiceSearchData` (watcher glitch without this variable)
      this.changevoucherSearchData = cngvoucherSearchData.defaultData();

      this.resetBruttoNetto(); // reset `bruttoNetto` when clear all filters
    }
  }

  @Watch('changevoucherSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: ChangeVoucherSearchData, oldVal: ChangeVoucherSearchData) {
    // avoid loop when `changevoucherSearchData` was reset
    if (!this.isChangeVoucherearchDataWasCleared) {
      const payload = Object.assign({}, this.changevoucherSearchData);      
      payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
      payload.isUserInOnlyGs = this.isUserOnlyGs;
      payload.zrNummer = this.getZrNummerDependOnRole();
      this.actionUpdateChangeVoucherSearchData(Object.assign({}, payload)); // (GSP-075)  use `Object.assign` to break reference for `changevoucherSearchData` to get new data from backend on any input clear or multiple select
    }
    this.isChangeVoucherearchDataWasCleared = false;
  }

  private clearFacetFilter: boolean = false;
  @Watch('changeVouchersSearchParams', { deep: true })
  public async onOptionsFacetFilterChanged(newVal: any, oldVal: any) {    
    this.clearFacetFilter = newVal.andClauseFieldsIds.length == 0;    
  }

  private documentAeaName(value: number) {
    let i18key = changevoucherTypeList.find((x) => x.index == value)?.i18nKey;    
    let formattedResult = i18key ? `${this.$t(i18key)} (${value})` : value;    
    return formattedResult;
  }
  private documentStatusTypeName(value: number) {      
    let i18key = changevoucherStatusTypeList.find((x) => x.index == value)?.i18nKey;    
    //let formattedResult = i18key ? `${this.$t(i18key)} (${value})` : value; 
    let formattedResult = i18key ? `${this.$t(i18key)}` : value;    
    return formattedResult;
  }

  private intStatusTypeName(value: number) {      
    let i18key = changevoucherInternalStatusList.find((x) => x.index == value)?.i18nKey;    
    //let formattedResult = i18key ? `${this.$t(i18key)} (${value})` : value;    
    let formattedResult = i18key ? `${this.$t(i18key)}` : value;   
    return formattedResult;
  }


  private loadingAeaTyps = false;
  private async getAeaTyps() {
    if (this.getChangeVoucherAeaTyps?.length > 0) {
      this.aeaTyps = this.getChangeVoucherAeaTyps;      
    } else {
      this.loadingAeaTyps = true;      
      let payload = this.setChangeVoucherSearchFormFiltersPayload();      
      await this.actionGetChangeVoucherAeaTyps(payload)
        .then((result: any) => {
          this.aeaTyps = this.getChangeVoucherAeaTyps;          
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingAeaTyps = false;
        });
    }
  }

  private loadingDocumentTypes = false;
  private async getDocumentTypes() {
    if (this.getChangeVoucherStatusTypes?.length > 0) {
      this.documentTypes = this.getChangeVoucherStatusTypes;
    } else {
      this.loadingDocumentTypes = true;      
      let payload = this.setChangeVoucherSearchFormFiltersPayload();      
      await this.actionGetChangeVoucherStatusTypes(payload)
        .then((result: any) => {
          // this.documentTypes = result;
          this.documentTypes = this.getChangeVoucherStatusTypes; // result transformation in `setChangeVoucherStatusTypes` mutation
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingDocumentTypes = false;
        });
    }
  }

  private loadingIntStatusTypes = false;
  private async getIntStatusTypes() {
    if (this.getChangeVoucherIntStatusTypes?.length > 0) {
      this.intStatusType= this.getChangeVoucherIntStatusTypes;
    } else {
      this.loadingIntStatusTypes = true;      
      let payload = this.setChangeVoucherSearchFormFiltersPayload();
      await this.actionGetChangeVoucherIntStatusTypes(payload)
        .then((result: any) => {
          this.intStatusType = this.getChangeVoucherIntStatusTypes;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingIntStatusTypes = false;
        });
    }
  }

  private loadingMembers = false;
  private async getMembers() {
    if (this.getChangeVoucherMembers?.length > 0) {
      this.members = this.getChangeVoucherMembers;
    } else {
      this.loadingMembers = true;
      let payload = this.setChangeVoucherSearchFormFiltersPayload();
      await this.actionGetChangeVoucherMembers(payload)
        .then((result: any) => {
          this.members = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingMembers = false;
        });
    }
  }

  private loadingSuppliers = false;
  private async getSuppliers() {
    if (this.getChangeVoucherSuppliers?.length > 0) {
      this.suppliers = this.getChangeVoucherSuppliers;
    } else {
      this.loadingSuppliers = true;
      let payload = this.setChangeVoucherSearchFormFiltersPayload();
      await this.actionGetChangeVoucherSuppliers(payload)
        .then((result: any) => {
          if (this.supplierTooltipInfo) {
            // ONLY_DEV (GSP-117) for now loading tooltip only for dev
            this.suppliers = result.map((item: any) =>
              Object.assign(
                item,
                this.supplierTooltipInfo.find((y: any) => y.zrNummer == item.id)
              )
            );

            this.actionUpdateChangeVoucherSuppliers(this.suppliers);
          } else {
            this.suppliers = result;
          }

        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingSuppliers = false;
        });
    }
  }

  //invoiceSearchData = invSearchData.defaultData();
  changevoucherSearchData = cngvoucherSearchData.defaultData();

  documentTypes: any = [];
  aeaTyps : any = [];
  intStatusType : any = [];
  members = [];
  suppliers = [];

  @Watch('$i18n.locale')
  private onLocaleChanged() {
    let backendIndices = this.getChangeVoucherStatusTypes.map((x: any) => x.index);
    this.documentTypes = voucherStatusesWithLocale(backendIndices);
    this.intStatusType = voucherIntStatusesWithLocale(backendIndices);
    let backendIndicesAeaType = this.getChangeVoucherAeaTyps.map((x: any) => x.index);
    this.aeaTyps = voucherAeaTypeWithLocale(backendIndicesAeaType);
  }

  //#region BruttoNetto logic
  bruttoNetto: any = [];
  bruttoNettoMinMax: any = [];

  private loadingMinMaxBruttoNetto = false;
  private async getMinMaxBruttoNetto() {
    this.loadingMinMaxBruttoNetto = true;
    let payload = this.setChangeVoucherSearchFormFiltersPayload();
    await this.actionGetChangeVoucherMinMaxBruttoNetto(payload)
      .then((result: any) => {
        Object.keys(result).forEach((key, index) => {
          result[key] = result[key].toLocaleString(this.$i18n.locale);
        });
        this.bruttoNettoMinMax = Object.assign({}, result);
        this.bruttoNetto = Object.assign({}, result);
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => {
        this.loadingMinMaxBruttoNetto = false;
      });    
  }

  get bruttoMin() {
    return this.changevoucherSearchData.bruttoNetto?.bruttoMin
      ? (+this.changevoucherSearchData.bruttoNetto?.bruttoMin)?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.isChangeVoucherSearchDataEmpty
      ? this.bruttoNettoMinMax?.bruttoMin?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.bruttoNetto?.bruttoMin?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
  }
  get bruttoMax() {
    return this.changevoucherSearchData.bruttoNetto?.bruttoMax
      ? (+this.changevoucherSearchData.bruttoNetto?.bruttoMax)?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.isChangeVoucherSearchDataEmpty
      ? this.bruttoNettoMinMax.bruttoMax?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.bruttoNetto.bruttoMax?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
  }
  get nettoMin() {
    return this.changevoucherSearchData.bruttoNetto?.nettoMin
      ? (+this.changevoucherSearchData.bruttoNetto?.nettoMin)?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.isChangeVoucherSearchDataEmpty
      ? this.bruttoNettoMinMax.nettoMin?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.bruttoNetto.nettoMin?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
  }
  get nettoMax() {
    return this.changevoucherSearchData.bruttoNetto?.nettoMax
      ? (+this.changevoucherSearchData.bruttoNetto?.nettoMax)?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.isChangeVoucherSearchDataEmpty
      ? this.bruttoNettoMinMax.nettoMax?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.bruttoNetto.nettoMax?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
  }

  get bruttoOneText() {
    let result = '';
    if (this.getChangeVoucherSearchData.bruttoNetto?.bruttoMin && this.getChangeVoucherSearchData.bruttoNetto?.bruttoMax) {
      result = `${(+this.getChangeVoucherSearchData.bruttoNetto?.bruttoMin)} ${this.$t(
        'to'
      )} ${(+this.getChangeVoucherSearchData.bruttoNetto?.bruttoMax)?.toLocaleString(this.$i18n.locale)}`;
    }

    if (this.getChangeVoucherSearchData.bruttoNetto?.bruttoMin && !this.getChangeVoucherSearchData.bruttoNetto?.bruttoMax) {
      result = `${(+this.getChangeVoucherSearchData.bruttoNetto?.bruttoMin)?.toLocaleString(this.$i18n.locale)} ${this.$t(
        'to'
      )} ${this.bruttoNettoMinMax.bruttoMax}`;
    }

    if (!this.getChangeVoucherSearchData.bruttoNetto?.bruttoMin && this.getChangeVoucherSearchData.bruttoNetto?.bruttoMax) {
      result = `${this.bruttoNettoMinMax.bruttoMin} ${this.$t('to')} ${(+this.getChangeVoucherSearchData.bruttoNetto
        ?.bruttoMax)?.toLocaleString(this.$i18n.locale)}`;
    }

    return result;
  }

  get nettoOneText() {
    let result = '';
    if (this.getChangeVoucherSearchData.bruttoNetto?.nettoMin && this.getChangeVoucherSearchData.bruttoNetto?.nettoMax) {
      result = `${(+this.getChangeVoucherSearchData.bruttoNetto?.nettoMin)?.toLocaleString(this.$i18n.locale)} ${this.$t(
        'to'
      )} ${(+this.getChangeVoucherSearchData.bruttoNetto?.nettoMax)?.toLocaleString(this.$i18n.locale)}`;
    }

    if (this.getChangeVoucherSearchData.bruttoNetto?.nettoMin && !this.getChangeVoucherSearchData.bruttoNetto?.nettoMax) {
      result = `${(+this.getChangeVoucherSearchData.bruttoNetto?.nettoMin)?.toLocaleString(this.$i18n.locale)} ${this.$t(
        'to'
      )} ${this.bruttoNettoMinMax.nettoMax}`;
    }

    if (!this.getChangeVoucherSearchData.bruttoNetto?.nettoMin && this.getChangeVoucherSearchData.bruttoNetto?.nettoMax) {
      result = `${this.bruttoNettoMinMax.nettoMin} ${this.$t('to')} ${(+this.getChangeVoucherSearchData.bruttoNetto
        ?.nettoMax)?.toLocaleString(this.$i18n.locale)}`;
    }
    
    return result;
  }

  resetBruttoNetto() {
    this.bruttoNetto = Object.assign({}, this.bruttoNettoMinMax);
  }

  clearNetto() {
    this.changevoucherSearchData.bruttoNetto.nettoMin = null;
    this.changevoucherSearchData.bruttoNetto.nettoMax = null;    
    this.changevoucherSearchData.triggerChange = !this.changevoucherSearchData.triggerChange;
    this.changevoucherSearchData = Object.assign({}, this.changevoucherSearchData);
  }

  clearBrutto() {
    this.changevoucherSearchData.bruttoNetto.bruttoMin = null;
    this.changevoucherSearchData.bruttoNetto.bruttoMax = null;
    this.changevoucherSearchData.triggerChange = !this.changevoucherSearchData.triggerChange;
    this.changevoucherSearchData = Object.assign({}, this.changevoucherSearchData);
  }

  formatInput(value: any) {
    if (this.$i18n.locale == 'de') {
      value = value.replaceAll('.', '').replace(',', '.').trim();
    }
    return value;
  }

  checkBruttoMin(e: any) {    
    let value = this.formatInput(e.target.value);
    this.bruttoNetto.bruttoMin = (+value).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.checkWithDbMinMaxAndUpdate('bruttoMin');
  }

  checkBruttoMax(e: any) {
    let value = this.formatInput(e.target.value);
    this.bruttoNetto.bruttoMax = (+value).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.checkWithDbMinMaxAndUpdate('bruttoMax');
  }

  checkNettoMin(e: any) {
    let value = this.formatInput(e.target.value);
    this.bruttoNetto.nettoMin = (+value).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.checkWithDbMinMaxAndUpdate('nettoMin');
  }

  checkNettoMax(e: any) {
    let value = this.formatInput(e.target.value);
    this.bruttoNetto.nettoMax = (+value).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.checkWithDbMinMaxAndUpdate('nettoMax');
  }

  checkWithDbMinMaxAndUpdate(control: string) {    
    if (this.getChangeVoucherMinMaxBruttoNetto?.bruttoMin != this.bruttoNetto.bruttoMin || control == 'bruttoMin') {
      this.changevoucherSearchData.bruttoNetto.bruttoMin = this.bruttoNetto.bruttoMin;
    }
    if (this.getChangeVoucherMinMaxBruttoNetto?.bruttoMax != this.bruttoNetto.bruttoMax || control == 'bruttoMax') {
      this.changevoucherSearchData.bruttoNetto.bruttoMax = this.bruttoNetto.bruttoMax;
    }
    if (this.getChangeVoucherMinMaxBruttoNetto?.nettoMin != this.bruttoNetto.nettoMin || control == 'nettoMin') {
      this.changevoucherSearchData.bruttoNetto.nettoMin = this.bruttoNetto.nettoMin;
    }
    if (this.getChangeVoucherMinMaxBruttoNetto?.nettoMax != this.bruttoNetto.nettoMax || control == 'nettoMax') {
      this.changevoucherSearchData.bruttoNetto.nettoMax = this.bruttoNetto.nettoMax;
    }

    if (this.$i18n.locale == 'de') {
      Object.keys(this.changevoucherSearchData.bruttoNetto).forEach((key, index) => {
        if (this.changevoucherSearchData.bruttoNetto[key] != null) {
          this.changevoucherSearchData.bruttoNetto[key] = this.changevoucherSearchData.bruttoNetto[key]
            .replaceAll('.', '')
            .replace(',', '.');
        }
      });
    }
    this.changevoucherSearchData.triggerChange = !this.changevoucherSearchData.triggerChange; // (GSP-116) need this trick to send request to backend, since data is cached somehow by reference, and comparing new/old `invoiceSearchData` for `bruttoNetto` in watcher return equal objects (`areEqual` == true) and NOT send request to backend to avoid duplicate requests
    this.changevoucherSearchData = Object.assign({}, this.changevoucherSearchData);
  }
  //#endregion

  changeDate(value: any) {
    this.changevoucherSearchData.erfassungsdatum = value || undefined; // new Date(value).toJSON();
    this.triggerSearchDataUpdate();
  }
  changeDueDate(value: any) {
    this.changevoucherSearchData.dueDate = value || undefined; // new Date(value).toJSON();
    this.triggerSearchDataUpdate();
  }

  triggerSearchDataUpdate() {
    // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
    const payload = Object.assign({}, this.changevoucherSearchData);
    payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    payload.isUserInOnlyGs = this.isUserOnlyGs;
    payload.zrNummer = this.getZrNummerDependOnRole();

    this.changevoucherSearchData = Object.assign({}, payload); // workaround (sometimes data change is just ignored by watcher)
  }

  //#region Filtering autocomplete data based on previous autocomplete values
  private setChangeVoucherSearchFormFiltersPayload() {
    let newVal = this.changevoucherSearchData;
    let chipDataString= this.changeVouchersSearchParams.andClauseFieldsIds.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
    let payload: ChangeVoucherSearchData = {
      aeaType: newVal.aeaType,
      statusType: newVal.statusType,
      intStatusType: newVal.intStatusType,
      receiverNumbers: newVal.receiverNumbers, //mitglied,
      senderNumbers: newVal.senderNumbers, // lieferant,      
      erfassungsdatum: newVal.erfassungsdatum,
      bruttoNetto: newVal.bruttoNetto,
      dueDate: newVal.dueDate,
      isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
      isUserInOnlyGs: this.isUserOnlyGs,
      zrNummer: this.getZrNummerDependOnRole(),
      chipDataString: chipDataString,
    };
    return payload;
  }

  showMenu() {
    this.refDocumentTypeCombobox.activateMenu();
  }

  reloadAEaTypsList: boolean = false;
  reloadDocumentStatusList: boolean = false;
  reloadIntStatusTypesList: boolean = false;
  reloadMembersList: boolean = false;
  reloadSuppliersList: boolean = false;
  reloadBruttoNettoText: boolean = false;

  filterDocumentStatus() {
    if ((this.changevoucherSearchData?.aeaType.length > 0 ||
      this.changevoucherSearchData?.statusType.length > 0 ||
      this.changevoucherSearchData?.intStatusType.length > 0 ||
      this.changevoucherSearchData?.receiverNumbers.length > 0 ||
      this.changevoucherSearchData?.senderNumbers.length > 0)
      || this.reloadDocumentStatusList
      || this.changeVouchersSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter
    ) {
      this.loadingDocumentTypes = true;
      let payload = this.setChangeVoucherSearchFormFiltersPayload();
      this.reloadDocumentStatusList = !this.reloadDocumentStatusList;
      this.actionGetChangeVoucherStatusTypes(payload)
        .then((result: any) => {
          this.documentTypes = this.getChangeVoucherStatusTypes; // result transformation in `setChangeVoucherStatusTypes` mutation
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingDocumentTypes = false;
          this.$nextTick(() => {
            this.refDocumentTypeCombobox.activateMenu();
          });
        });
    }
  }

  filterIntStatusTypes() {
    if ((this.changevoucherSearchData?.aeaType.length > 0 ||
      this.changevoucherSearchData?.statusType.length > 0 ||
      this.changevoucherSearchData?.receiverNumbers.length > 0 ||
      this.changevoucherSearchData?.senderNumbers.length > 0 ||
      this.changevoucherSearchData?.intStatusType.length > 0)
      || this.reloadIntStatusTypesList
      || this.changeVouchersSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter
    ) {
      this.loadingIntStatusTypes = true;
      let payload = this.setChangeVoucherSearchFormFiltersPayload();
      this.reloadIntStatusTypesList = !this.reloadIntStatusTypesList;
      this.actionGetChangeVoucherIntStatusTypes(payload)
        .then((result: any) => {
          this.intStatusType = this.getChangeVoucherIntStatusTypes; // result transformation in `setChangeVoucherStatusTypes` mutation
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingIntStatusTypes = false;
          this.$nextTick(() => {
            this.refintStatusTypeCombobox.activateMenu();
          });
        });
    }
  }

  filterAEaTyps() {
    if ((this.changevoucherSearchData.aeaType?.length > 0 ||
      this.changevoucherSearchData?.statusType.length > 0 ||
      this.changevoucherSearchData?.intStatusType.length > 0 ||
      this.changevoucherSearchData?.receiverNumbers.length > 0 ||
      this.changevoucherSearchData?.senderNumbers.length > 0)
      || this.reloadAEaTypsList
      || this.changeVouchersSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter
    ) {
      this.loadingAeaTyps = true;
      this.reloadAEaTypsList = !this.reloadAEaTypsList;
      let payload = this.setChangeVoucherSearchFormFiltersPayload();

      this.actionGetChangeVoucherAeaTyps(payload)
        .then((result: any) => {
          this.aeaTyps = this.getChangeVoucherAeaTyps;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingAeaTyps = false;
          this.$nextTick(() => {
            this.refAeaTypeCombobox.activateMenu();
          });
        });
    }
  }

  filterMembers() {
    if ((this.changevoucherSearchData.aeaType?.length > 0 ||
      this.changevoucherSearchData.statusType?.length > 0 ||
      this.changevoucherSearchData.intStatusType?.length > 0 ||
      this.changevoucherSearchData.receiverNumbers?.length > 0 ||
      this.changevoucherSearchData.senderNumbers?.length > 0)
      || this.reloadMembersList
      || this.changeVouchersSearchParams.andClauseFieldsIds.length > 0   
      || this.clearFacetFilter   
    ) {
      this.loadingMembers = true;
      let payload = this.setChangeVoucherSearchFormFiltersPayload();
      this.reloadMembersList = !this.reloadMembersList;
      this.actionGetChangeVoucherMembers(payload)
        .then((result: any) => {
          this.members = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingMembers = false;

          this.$nextTick(() => {
            this.refMembersCombobox.activateMenu();
          });
        });
    }
  }

  filterSupplier() {
    if ((this.changevoucherSearchData.aeaType?.length > 0 ||
      this.changevoucherSearchData.statusType?.length > 0 ||
      this.changevoucherSearchData.intStatusType?.length > 0 ||
      this.changevoucherSearchData.receiverNumbers?.length > 0 ||
      this.changevoucherSearchData.senderNumbers?.length > 0)
      || this.reloadSuppliersList
      || this.changeVouchersSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter      
    ) {
      this.loadingSuppliers = true;
      let payload = this.setChangeVoucherSearchFormFiltersPayload();
      this.reloadSuppliersList = !this.reloadSuppliersList;

      this.actionGetChangeVoucherSuppliers(payload)
        .then((result: any) => {
          // this.suppliers = result;
          if (this.supplierTooltipInfo) {
            // ONLY_DEV (GSP-117) for now loading tooltip only for dev
            this.suppliers = result.map((item: any) =>
              Object.assign(
                item,
                this.supplierTooltipInfo.find((y: any) => y.zrNummer == item.id)
              )
            );
          } else {
            this.suppliers = result;
          }

        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingSuppliers = false;

          this.$nextTick(() => {
            this.refSuppliersCombobox.activateMenu();
          });
        });
    }
  }
  //#endregion
  searchAeaTypeInput = '';
  searchDocumentTypeInput = '';
  searchIntStatusTypeInput = '';
  searchMembersInput = '';
  searchSupplierInput = '';
  searchModelNameInput = '';
  searchStatusInput = '';

  clearSearchInput() {
    this.searchAeaTypeInput = '';
    this.searchDocumentTypeInput = '';
    this.searchIntStatusTypeInput = '';
    this.searchStatusInput = '';
    this.searchMembersInput = '';
    this.searchModelNameInput = '';
    this.searchSupplierInput = '';
  }

  clearInvoiceSearchFormData() {
    this.actionResetInvoiceSearchFormData();
  }

  bruttoMinEnter() {
    (this.$refs['bruttoMaxValue'] as any).focus();
  }

  bruttoMaxEnter() {
    if (this.isOpenedMenuBruttoMinMax == true) {
      this.isOpenedMenuBruttoMinMax = false;
    }
  }

  nettoMinEnter() {
    (this.$refs['nettoMaxValue'] as any).focus();
  }

  nettoMaxEnter() {
    if (this.isOpenedMenuNettoMinMax == true) {
      this.isOpenedMenuNettoMinMax = false;
    }
  }

  onMenuNettoMinMax(opened: any) {
    if (opened == true) {
      this.isOpenedMenuNettoMinMax = true;
    }
    else {
      this.isOpenedMenuNettoMinMax = false;
    }
  }

  onMenuBruttoMinMax(opened: any) {
    if (opened == true) {
      this.isOpenedMenuBruttoMinMax = true;
    }
    else {
      this.isOpenedMenuBruttoMinMax = false;
    }
  }

  nettoTextDown() {
    setTimeout(() => {
    let inputMinValue = (this.$refs['nettoMinValue'] as any).$el.querySelector('input')    
    inputMinValue.select()
    }, 50);
    (this.$refs['nettoMinValue'] as any).focus();
  }

  bruttoTextDown() {
    let inputMinValue = (this.$refs['bruttoMinValue'] as any).$el.querySelector('input')
    setTimeout(() => {
      inputMinValue.select()
    }, 50);
    (this.$refs['bruttoMinValue'] as any).focus();
  }

  nettoTextClick() {
    setTimeout(() => {
      (this.$refs['nettoMinValue'] as any).focus();
      let inputMinValue = (this.$refs['nettoMinValue'] as any).$el.querySelector('input');
      inputMinValue.select()
    }, 200);
  }

  bruttoTextClick() {
    let gggg = Object.assign({}, this.changevoucherSearchData);
    // if ((this.changevoucherSearchData?.aeaType.length > 0 ||
    //   this.changevoucherSearchData?.statusType.length > 0 ||
    //   this.changevoucherSearchData?.intStatusType.length > 0 ||
    //   this.changevoucherSearchData?.receiverNumbers.length > 0 ||
    //   this.changevoucherSearchData?.senderNumbers.length > 0)
    //   || this.reloadBruttoNettoText
    // ) {
    //   console.log('---------------bruttoTextClick--------------->>>: this.reloadBruttoNettoText  ');
    //   this.loadingMinMaxBruttoNetto = true;
    //   let payload = this.setChangeVoucherSearchFormFiltersPayload();
    //   this.reloadBruttoNettoText = !this.reloadBruttoNettoText;
    //   this.actionGetChangeVoucherMinMaxBruttoNetto(payload)
    //     .then((result: any) => {
    //       Object.keys(result).forEach((key, index) => {
    //         result[key] = result[key].toLocaleString(this.$i18n.locale);
    //       });
    //       this.bruttoNettoMinMax = Object.assign({}, result);
    //       this.bruttoNetto = Object.assign({}, result);
    //     })
    //     .catch((err: any) => {
    //       logger.error(err);
    //     })
    //     .finally(() => {
    //       this.loadingMinMaxBruttoNetto = false;
    //     });
    // }

    setTimeout(() => {
      (this.$refs['bruttoMinValue'] as any).focus();
      let inputMinValue = (this.$refs['bruttoMinValue'] as any).$el.querySelector('input');
      inputMinValue.select()
    }, 200);
  }

}
