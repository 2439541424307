import UserData from '@/shared/model/userData';

import { UserDataState } from './types';

export const state = (): UserDataState => initialState();

export const initialState = (): UserDataState => ({
  userDatum: UserData.parse({}),
  userData: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [''],
      andClauseFieldsIds: [],
    },
  },
});
