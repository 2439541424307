import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { PasswordStatus } from './PasswordStatus';

@Component({ name: 'password', components: {} })
export default class Password extends Vue {
  public showPassword = false;
  public password = '';

  @Prop({ default: 8, type: Number })
  private minPasswordlLength!: number;

  @Prop({ default: 255, type: Number })
  private maxPasswordlLength!: number;

  @Prop({ default: () => '' })
  private dynamicValidator!: string;

  @Prop()
  private label?: string;
  @Prop()
  private hint?: string;

  @Prop({ default: true, type: Boolean })
  private showStrength?: boolean;

  // Prop for confirmed password validation see (https://logaretm.github.io/vee-validate/guide/rules.html#confirmed)
  @Prop({ default: () => '' })
  private vid!: string;

  @Prop()
  private value?: string;

  @Prop({ default: true, type: Boolean })
  private rounded?: boolean;

  @Watch('value')
  public onOptionsChanged() {
    this.password = this.value || this.password;
  }

  get isConfirmation(): boolean {
    return this.dynamicValidator.indexOf('confirmed:') > 0;
  }

  get passwordInfo(): { color: string; text: TranslateResult } {
    const result = this.checkPassword();
    return result;
  }

  get computedLabel() {
    let result = this.isConfirmation
      ? this.$t('password.repeat_label')
      : this.$t('password.label');
    if (this.label) {
      result = this.label;
    }
    return result;
  }

  get computedHint() {
    let result = this.isConfirmation
      ? ''
      : this.$t('password.hint', { 0: this.minPasswordlLength });
    if (this.hint) {
      result = this.hint;
    }
    return result;
  }

  get passwordStrength(): {
    [key in PasswordStatus]: {
      color: string;
      text: TranslateResult;
    };
  } {
    return {
      WEAK: {
        color: 'error',
        text: this.$i18n.t(`password.strength_weak`),
      },
      OK: {
        color: 'grey',
        text: this.$i18n.t(`password.strength_ok`),
      },
      STRONG: {
        color: 'success',
        text: this.$i18n.t(`password.strength_strong`),
      },
    };
  }

  //TODO: test this method in JEST (discuss what exactly to test)
  private checkPassword() {
    let strength = 0;
    let result = this.passwordStrength.WEAK;

    let matchedCase = new Array();
    matchedCase.push(`[^A-Za-z0-9]`); // Special characters
    matchedCase.push('[A-Z]'); // Uppercase letters
    matchedCase.push('[0-9]'); // Numbers
    matchedCase.push('[a-z]'); // Lowercase letters

    for (let i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(this.password)) {
        strength++;
      }
    }
    // if the same number or letter repeated 2 times (twice), then reduce the strength of password
    if (
      new RegExp('([a-zA-Z0-9])\\1{2}').test(this.password) &&
      this.password.length < this.minPasswordlLength
    ) {
      strength--;
    }

    if (
      strength === 1 ||
      (strength == 2 && this.password.length < this.minPasswordlLength)
    ) {
      result = this.passwordStrength.WEAK;
    } else if (
      (strength == 2 || strength == 3) &&
      this.password.length >= this.minPasswordlLength
    ) {
      result = this.passwordStrength.OK;
    } else if (
      strength == 4 &&
      this.password.length >= this.minPasswordlLength
    ) {
      result = this.passwordStrength.STRONG;
    }
    return result;
  }

  private onPasswordInput() {
    this.$emit('update:password', this.password);
  }

  private submit() {
    this.$emit('submit:form');
  }
}
