
import { MutationTree } from 'vuex';
import { ChangeVoucherDocumentState } from './types';
import Vue from 'vue';
import changeVoucherDocument, { ChangeVoucherDocument, Value } from '@/shared/model/changeVoucherDocument';

export const mutations: MutationTree<ChangeVoucherDocumentState> = {
  setChangeVoucherDocuments(state, payload: Value) {
    state.changeVoucherDocuments.items = payload.value.map((x) => changeVoucherDocument.parse(x));
    state.changeVoucherDocuments.total = payload['@odata.count'] || 0;
    state.changeVoucherDocuments.isLoading = false;
  },
  setChangeVoucherDocumentsTotal(state, payload: number) {
    state.changeVoucherDocuments.total = payload;
  },
  setChangeVoucherDocumentsIsLoading(state, payload: boolean) {
    state.changeVoucherDocuments.isLoading = payload;
  },
};
