export interface ChangeVoucherNotice {
    id: number; // uint  
    title: string; // string?  
    text: string; // string?  
    change_voucher_id: number; // uint  
    //change_voucher: gutschriftsprozess.DataAccessPostgreSQL.Database.DataModel.ChangeVoucherDm; // gutschriftsprozess.DataAccessPostgreSQL.Database.DataModel.ChangeVoucherDm  
    bearbeit_von: string; // string?  
    bearbeit_am: string; // System.DateTime?  
    erstellt_von: string; // string?  
    erstellt_am: string; // System.DateTime?  
 
}

export interface Value {
  value: ChangeVoucherNotice[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ChangeVoucherNotice>): any {
  return {
    id: data?.id || undefined,  
    title: data?.title || '',  
    text: data?.text || '',  
    change_voucher_id: data?.change_voucher_id || 0,  
   // change_voucher: data?.change_voucher || '',  
    // bearbeit_von: data?.bearbeit_von || '',  
    // bearbeit_am: data?.bearbeit_am || '',  
    // erstellt_von: data?.erstellt_von || '',  
    // erstellt_am: data?.erstellt_am || '',  
  };
}

function parse(data?: Partial<ChangeVoucherNotice>): ChangeVoucherNotice {
  return {
    id: data?.id || 0,  
    title: data?.title || '',  
    text: data?.text || '',  
    change_voucher_id: data?.change_voucher_id || 0,  
    //change_voucher: data?.change_voucher || '',  
    bearbeit_von: data?.bearbeit_von || '',  
    bearbeit_am: data?.bearbeit_am || '',  
    erstellt_von: data?.erstellt_von || '',  
    erstellt_am: data?.erstellt_am || '',  
  };
}

export default {
  parse,
  toAPI: toAPI,
};
