import { GetterTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../../types';
import {
  vmeSingleCompanyAdminGroup,
  vmeSingleCompanyAdminGroupId,
  vmeGlobalAdminGroup,
  vmeGlobalAdminGroupId,
  vmeSupportGroup,
  vmeSupportGroupId,
  vmePortalUserGroup,
  vmePortalUserGroupId,
} from '@/shared/backend/account';

export const getters: GetterTree<AuthState, RootState> = {
  /**
   * Answers whether the user is authenticated (an access token is provided).
   */
  // return a function so it is no cached
  ['token']: (state: AuthState) => () => state.token,
  /**
   * Answers the details of the current employee.
   */
  ['user']: (state: AuthState) => state.account,
  ['isInitialRedirect']: (state: AuthState) => state.isInitialRedirect,
  ['azureUser']: (state: AuthState) => state.azureData,
  ['environment']: (state: AuthState) => state.environment,
  ['isDevEnv']: (state: AuthState, getters: any): boolean => {
    console.log("store.getters['auth/environment']?.toLowerCase() :>> ", getters.environment?.toLowerCase());
    return (
      (getters.environment &&
        (['development', 'localdev'].includes(getters.environment?.toLowerCase()) ||
          getters.environment.toLowerCase().includes('dev'))) ||
      !getters.environment
    ); // !! (GSP-117) if no `getters.environment` then consider it as dev to avoid show unwanted things in prod
  },
  ['isStagingEnv']: (state: AuthState, getters: any): boolean => {
    console.log("store.getters['auth/environment']?.toLowerCase() :>> ", getters.environment?.toLowerCase());
    return (
      getters.environment &&
      (['staging', 'stage'].includes(getters.environment?.toLowerCase()) ||
        getters.environment.toLowerCase().includes('stage'))
    );
  },
  ['isProductionEnv']: (state: AuthState, getters: any): boolean => {
    console.log("store.getters['auth/environment']?.toLowerCase() :>> ", getters.environment?.toLowerCase());
    return (
      getters.environment &&
      (['production'].includes(getters.environment?.toLowerCase()) ||
        getters.environment.toLowerCase().includes('prod'))
    );
  },

  ['zrNummer']: (state: AuthState) => state.zrNummer,
  ['zrNummerWithRoles']: (state: AuthState) => state.zrNummerWithRoles,
  ['testGsUserZrNummer']: (state: AuthState) => state.testGsUserZrNummer,
  ['supplierTooltipInfo']: (state: AuthState) => state.supplierTooltipInfo,

  // ['isUserInAzureAdminGroup']: (state: AuthState) => state.isUserInAzureAdminGroup,
  // `vme-admin` or `vme-support`(see all but cannot change) groups
  ['isUserInAzureAdminGroup']: (state: AuthState) => {
    // return !!(
    //   state.userAdminGroups.find((x) => x.displayName == vmeSupportGroup) ||
    //   state.userAdminGroups.find((x) => x.displayName == vmeGlobalAdminGroup)
    // );
    return !!(
      state.userAdminGroups.find((x) => [vmeSupportGroup, vmeGlobalAdminGroup].includes(x.displayName)) ||
      // (AD-62) Use Azure GUID for filtering (displayName is `null` in production for some reason)
      state.userAdminGroups.find((x) => [vmeSupportGroupId, vmeGlobalAdminGroupId].includes(x.id))
    );
  },
  ['isUserSuperAdmin']: (state: AuthState) => {
    return !!(
      state.userAdminGroups.find((x) => x.displayName == vmeGlobalAdminGroup) ||
      // Use Azure GUID for filtering (displayName is `null` in production for some reason)
      state.userAdminGroups.find((x) => x.id == vmeGlobalAdminGroupId)
    );
  },
  ['isUserSupport']: (state: AuthState) => {
    return !!(
      state.userAdminGroups.find((x) => x.displayName == vmeSupportGroup) ||
      // Use Azure GUID for filtering (displayName is `null` in production for some reason)
      state.userAdminGroups.find((x) => x.id == vmeSupportGroupId)
    );
  },
  ['isUserGsUser']: (state: AuthState, getters) => {
    return !!(
      state.userAdminGroups.find((x) => [vmeSingleCompanyAdminGroup, vmePortalUserGroup].includes(x.displayName)) ||
      // Use Azure GUID for filtering (displayName is `null` in production for some reason)
      state.userAdminGroups.find((x) => [vmeSingleCompanyAdminGroupId, vmePortalUserGroupId].includes(x.id))
    );
  },

  isUserOnlyGs(state, getters: any): boolean {
    return getters.isUserGsUser && !getters.isUserInAzureAdminGroup;
  },

  // (GSP-147) getters related to admin-panel rights for `GsUser` (not Admin) for different modules
  isUserHasRightsForDocuments(state, getters: any) {
    return getters.isUserInAzureAdminGroup || (getters.isUserOnlyGs && !!getters.zrNummerWithRoles?.zrdocumentZrNummer);
  },
  isUserHasRightsForInvoices(state, getters: any) {
    return getters.isUserInAzureAdminGroup || (getters.isUserOnlyGs && !!getters.zrNummerWithRoles?.invoiceZrNummer);
  },
  isUserHasRightsForVouchers(state, getters: any) {
    return getters.isUserInAzureAdminGroup || (getters.isUserOnlyGs && !!getters.zrNummerWithRoles?.voucherZrNummer);
  },

  isUserOnlyGsAndHasTestZr(state, getters: any): boolean {
    const testInvoiceZrNummer = getters.testGsUserZrNummer?.split(',') ?? [];
    const userZrNummer = getters.zrNummer?.split(',');

    const foundZr = userZrNummer ? testInvoiceZrNummer.some((r: any) => userZrNummer.includes(r)) : false;

    return getters.isUserGsUser && !getters.isUserInAzureAdminGroup && foundZr;
  },

  isLoggedIn(state): boolean {
    return null != state.account && state.account.id! != '';
  },
  getUser(state): any {
    return state.account;
  },
  getUserId(state): string | null {
    return state.account ? state.account.id! : null;
  },
  getAccountId(state): string | null {
    return state.accountId ? state.accountId! : null;
  },
  isEmailConfirmed(state): boolean {
    return state.account ? state.account.emailConfirmed : false;
  },

  isAdmin(state): boolean {
    return !!state.account && state.account!.authorities && state.account!.authorities!.includes('ADMIN');
  },
  isManager(state): boolean {
    return !!state.account && state.account!.authorities && state.account!.authorities!.includes('MANAGER');
  },
};
