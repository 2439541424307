
import { MutationTree } from 'vuex';
import { SaveListState } from './types';
import Vue from 'vue';
import saveList, { SaveList, Value } from '@/shared/model/saveList';

export const mutations: MutationTree<SaveListState> = {
  setSaveLists(state, payload: Value) {
    state.saveLists.items = payload.value.map((x) => saveList.parse(x));
    state.saveLists.total = payload['@odata.count'] || 0;
    state.saveLists.isLoading = false;
  },
  setSaveListsTotal(state, payload: number) {
    state.saveLists.total = payload;
  },
  setSaveListsIsLoading(state, payload: boolean) {
    state.saveLists.isLoading = payload;
  },
};
