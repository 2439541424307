import i18n from "@/i18n";

export interface ChangevoucherStatusType {
  index: number;
  i18nKey: string;
}

export enum ChangevoucherStatusTypeNameKey {
  draft = 'draft',
  waiting = 'waiting',
  submitted = 'submitted',
  booked = 'booked',
  rejected = 'rejected',
  archive = 'archive'
}

const RootI18nKey = 'changevoucherStatusType.';

export const changevoucherStatusTypeList: ChangevoucherStatusType[] = [
  { index: 0, i18nKey: RootI18nKey + ChangevoucherStatusTypeNameKey.draft },
  { index: 1, i18nKey: RootI18nKey + ChangevoucherStatusTypeNameKey.waiting },
  { index: 2, i18nKey: RootI18nKey + ChangevoucherStatusTypeNameKey.submitted },
  { index: 3, i18nKey: RootI18nKey + ChangevoucherStatusTypeNameKey.booked },
  { index: 4, i18nKey: RootI18nKey + ChangevoucherStatusTypeNameKey.rejected },
  { index: 5, i18nKey: RootI18nKey + ChangevoucherStatusTypeNameKey.archive },
];

export default {
  changevoucherStatusTypeList,
};

export function voucherStatusesWithLocale(backendIndices: any = []) {

  let array = [
    { index: 0, statusName: i18n.tc(`changevoucherStatusType.draft`) },
    { index: 1, statusName: i18n.tc(`changevoucherStatusType.waiting`) },
    { index: 2, statusName: i18n.tc(`changevoucherStatusType.submitted`) },
    { index: 3, statusName: i18n.tc(`changevoucherStatusType.booked`) },
    { index: 4, statusName: i18n.tc(`changevoucherStatusType.rejected`) },
    { index: 5, statusName: i18n.tc(`changevoucherStatusType.archive`) },
  ];
  if (backendIndices.length > 0) {
    array = array.filter((x) => backendIndices.includes(x.index));
  }

  return array;
}