import { AuthState } from './types';

export const state = (): AuthState => initialState();

export const initialState = (): AuthState => ({
  account: null,
  azureData: undefined,
  isInitialRedirect: !!localStorage.getItem('initialBrowserPath'),
  environment: undefined,
  // isUserInAzureAdminGroup: undefined,
  userAdminGroups: [],
  supplierTooltipInfo: undefined,
  zrNummer: undefined,
  zrNummerWithRoles: undefined,
  testGsUserZrNummer: undefined,
  // zrNummer: undefined,
  //zrNummer: '293000',

  accountId: undefined,
  error: false,
});
