export enum ROUTES {
  //Used in `$router.push()`
  home = 'home',
  changeVouchers = 'change-vouchers',
  changeVoucher = 'change-voucher',
  invoices = 'invoices',
  invoice = 'invoice',
  lists = 'lists',
  list = 'list',
  login = 'login',
  zrDocument = 'zr-document',
}
