export interface ChangeVoucherFacetSearchData extends ChangeVoucherSearchData {
  searchWord: string;
  chipDataString: string;

  // need all fields from SearchData for proper filtering and correct count numbers
}

export interface ChangeVoucherSearchData {
  aeaType: string[];
  statusType: string[];
  intStatusType: string[];
  receiverNumbers: string[]; // mitglied
  senderNumbers: string[]; // lieferant  
  erfassungsdatum: any; // date
  dueDate: any; // date  
  bruttoNetto?: any;
  isUserInAzureAdminGroup?: boolean; // `vme-admin` or `vme-support`(see all but cannot change) groups
  isUserInOnlyGs?: boolean; // determine whether records with ZR-Status = Archive should be displayed
  zrNummer?: string;
  processId?: string;
  chipDataString?: string;
  triggerChange?: boolean; // (GSP-116) need this artificial field to call backend when change bruto/netto in popup menu (othewise result somehow cached by reference and backend for new Netto/Brutto not called, since check object changes to avoid extra queries)
}

function defaultData(): ChangeVoucherSearchData {
  return {
    aeaType: [],
    statusType: [],
    intStatusType: [],
    receiverNumbers: [],
    senderNumbers: [],    
    erfassungsdatum: undefined,
    dueDate: undefined,   
    bruttoNetto: {},
    isUserInAzureAdminGroup: undefined,
    isUserInOnlyGs: undefined,

    triggerChange: false,
  };
}

export default {
  defaultData,
};
