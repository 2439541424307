import { ActionTree } from 'vuex';
import { ChangeVoucherDocumentState } from './types';
import { RootState } from '../../types';
import { defaultBackendChangeVoucherDocument } from '@/shared/backend/changeVoucherDocument';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import changeVoucherDocument, { Value, ChangeVoucherDocument } from '@/shared/model/changeVoucherDocument';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import DownloadUtils from '@/shared/utils/DownloadUtils';

const logger = new Logger('actions.changeVoucherDocuments');
export const actions: ActionTree<ChangeVoucherDocumentState, RootState> = {
  getChangeVoucherDocuments({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, changeVoucherId?: number }) {
    commit('setChangeVoucherDocumentsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getChangeVoucherDocumentsSearchParams;
    let changeVoucherId = payload?.changeVoucherId ?? 0;
    let organisationId = ''; // rootGetters[CONST.getOrganisation].id;
    return defaultBackendChangeVoucherDocument
      .getChangeVoucherDocuments(searchParams, changeVoucherId)
      .then((response: AxiosResponse<Value>) => {
        commit('setChangeVoucherDocuments', response.data);
        commit('setChangeVoucherDocumentsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setChangeVoucherDocumentsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getChangeVoucherDocument({ commit, dispatch }, id: string) {
    return defaultBackendChangeVoucherDocument
      .getChangeVoucherDocument(id)
      .then((response: AxiosResponse<ChangeVoucherDocument>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  async uploadDocument({ commit }, payload: any) {
    const data = new FormData();
    data.append('File', payload.document, payload.document.name);
    data.append('Id', payload.id);
    // data.append('ParentId', payload.parentId || ''); //  Fix `undefined` error when upload from `Document-data` tab (NOT dialog)

    return await defaultBackendChangeVoucherDocument
      .uploadDocument(data)
      .then((response) => {
        module_utils.ok(i18n.t(`success.upload_file`), commit, logger);
        return response.data;
      })
      .catch((e) => {
        commit('resetDocumentFile');
        module_utils.error(i18n.t(`error.upload_file`), commit, e, logger);
        throw e;
      });
  },
  async downloadDocument({ commit }, payload: any) {
    try {
      await defaultBackendChangeVoucherDocument.downloadDocument(payload).then((response) => {
        let fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: type });
        // add `.pdf` to have correct save for filename with dot(`.`)
        const url = DownloadUtils.createDownloadLink(blob, fileName + '.pdf');
        window.URL.revokeObjectURL(url);
        // }
      });
    } catch (e) {
      logger.error(e);
      module_utils.error(i18n.t(`error.download_file`), commit, e, logger);
      throw e;
    }
  },
  // deleteDocumentComment({ commit, dispatch }, id: string) {
  //   return defaultBackendChangeVoucherDocument
  //     .deleteDocumentComment(id)
  //     .then(response => {
  //       //TODO decide what to do after successfull delete
  //     })
  //     .catch(e => {
  //       module_utils.error(
  //         i18n.tc(`error.document_comment.delete`),
  //         commit,
  //         e,
  //         logger
  //       );
  //     });
  // },

  updateChangeVoucherDocument({ commit, dispatch }, file: ChangeVoucherDocument) {
    return defaultBackendChangeVoucherDocument
      .updateChangeVoucherDocument(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ChangeVoucherDocument;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.changeVoucherDocument_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteChangeVoucherDocument({ commit, dispatch }, id: string) {
    return defaultBackendChangeVoucherDocument
      .deleteChangeVoucherDocument(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ChangeVoucherDocument;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
