import { MutationTree } from 'vuex';
import { InvoiceState } from './types';
import Vue from 'vue';
import invoice, { Invoice, Value } from '@/shared/model/invoice';

export const mutations: MutationTree<InvoiceState> = {
  setInvoices(state, payload: Value) {
    state.invoices.items = payload.value.map((x) => invoice.parse(x));
    state.invoices.total = payload['@odata.count'] || 0;
    state.invoices.isLoading = false;
  },
  setInvoicesTotal(state, payload: number) {
    state.invoices.total = payload;
  },
  setInvoicesIsLoading(state, payload: boolean) {
    state.invoices.isLoading = payload;
  },

  setInvoicePdfIsLoading(state, payload: any){
    state.invoicePdfIsLoading = payload;
  },
  setInvoiceExcelIsLoading(state, payload: any){
    state.invoiceExcelIsLoading = payload;
  },
  setInvoiceMinMaxBruttoNetto(state, payload: any) {
    state.invoiceMinMaxBruttoNetto = payload;
  },
  setInvoiceDocumentType(state, payload: any) {
    state.invoiceDocumentTypes = payload;
  },
  setInvoiceMembers(state, payload: any) {
    state.invoiceMembers = payload;
  },
  setInvoiceSuppliers(state, payload: any) {
    state.invoiceSuppliers = payload;
  },


  setInvoiceSearchData(state, payload: any) {
    state.invoiceSearchData = payload;
  },
  setInitialLocalStorageInvoiceSearchData(state, payload: any) {
    state.initialLocalStorageInvoiceSearchData = payload;
  },
  resetInvoiceSearchFormData(state, payload: any) {
    state.invoiceSearchData.documentType = [];
    state.invoiceSearchData.receiverNumbers = [];
    state.invoiceSearchData.senderNumbers = [];
    state.invoiceSearchData.datum = undefined;
  },

};
