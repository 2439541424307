
import i18n from '../../i18n';
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper, numFields } from '../utils/backendHelper';
import changeVoucher, { ChangeVoucher, Value } from '../model/changeVoucher';
import ODataFilterBuilder from 'odata-filter-builder';
import { aaeTypeList } from '@/shared/model/smallPayloadModels/aaeType';
import { CONST } from '@/shared/utils/Constants';
import { ChangeVoucherFacetSearchData, ChangeVoucherSearchData } from '../model/smallPayloadModels/changeVoucherSearchData';

const logger = new Logger('backend.ChangeVoucher');
const searchableColumns: Map<string, string> = new Map([['InvoiceNumber', 'ExternalDocumentReference']]);
const defaultOrSearchFields = [
  'document_number',
  'commission_number',
  "cast(gross_amount,'Edm.String')",
  "cast(net_amount,'Edm.String')",  
  'sender_number',
  'name_lieferant',
  'process_ID',
  'receiver_number',
];
export interface BackendChangeVoucher {
  getChangeVoucher(id: string): AxiosPromise<ChangeVoucher>;
  getChangeVouchers: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  deleteChangeVoucher(id: string): AxiosPromise;
  updateChangeVoucher(ChangeVoucher: ChangeVoucher): AxiosPromise<any>;
  creaateChangeVoucher(ChangeVoucher: ChangeVoucher): AxiosPromise<any>;
  getChangeVoucherAeaTyps(searchData?: ChangeVoucherSearchData): AxiosPromise<any>;
  getChangeVoucherStatusTypes(searchData?: ChangeVoucherSearchData): AxiosPromise<any>;
  getChangeVoucherFacetSearch: (facetSearch: ChangeVoucherFacetSearchData) => AxiosPromise<Value>;
  getChangeVoucherMinMaxBruttoNetto(searchData?: ChangeVoucherSearchData): AxiosPromise<any>;
  getChangeVoucherIntStatusTypes(searchData?: ChangeVoucherSearchData): AxiosPromise<any>;
  getChangeVoucherMembers(searchData?: ChangeVoucherSearchData): AxiosPromise<any>;
  getChangeVoucherSuppliers(searchData?: ChangeVoucherSearchData): AxiosPromise<any>;
  getChangeVouchersPdfReport(data: any, searchParams: SearchParams): AxiosPromise<any>;
  getChangeVouchersExcelReport(data: any, searchParams: SearchParams): AxiosPromise<any>;
  getChangeVoucherDetailsPdfReport(data: any): AxiosPromise<any>;
}

export const defaultBackendChangeVoucher: BackendChangeVoucher = {
  getChangeVoucher(id: string): AxiosPromise<ChangeVoucher> {
    let url = `${URLS.changeVoucher}/${id}`;
    return instance.get<ChangeVoucher>(url);
  },
  getChangeVouchers(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    console.log('----------get ChangeVouchers-------------------------  >>>:searchParams.andClauseFieldsIds   ', searchParams.andClauseFieldsIds);
    if (searchParams.andClauseFieldsIds?.length > 0) {
      if (!searchParams.andClauseFieldsIds?.some((x: any) => x.chipField == 'all')) {
        console.log('reset orClauseFieldsIds :>> ');
        searchParams.orClauseFieldsIds = [];
      } else {
        let allChipField: any = searchParams.andClauseFieldsIds?.find((x: any) => x.chipField == 'all');
        searchParams.filter = allChipField?.searchWord;
        searchParams.orClauseFieldsIds = defaultOrSearchFields;

        localStorage.setItem('changeVoucher-filter', allChipField.searchWord);
      }
      let andClauseFieldsIds: Array<any> = searchParams.andClauseFieldsIds;

      andClauseFieldsIds.forEach((element: any, index: number) => {
        let chipField = searchableColumns.has(element.chipField)
          ? searchableColumns.get(element.chipField)
          : element.chipField;
          console.log('------------getChangeVouchers-------------chipField.toString()----------  >>>:   ', chipField.toString());
        if (numFields.includes(chipField.toLowerCase())) {
          var resultAnd = new ODataFilterBuilder('and');

          let number = +element.searchWord;
          let numberDelta = number < 0 ? -100 : 100;

          resultAnd.ge(chipField, Math.trunc(number * 100), false);
          resultAnd.lt(chipField, Math.trunc(number * 100 + numberDelta), false);
          odfb.and(resultAnd);
        } else if (element.chipField == 'all') {
          // do nothing
        } else {
          if (chipField.toString().toLowerCase() == 'sendernumber') {
            chipField = 'sender_number';
          }
          // else if (chipField.toString().toLowerCase() == 'process_id'){
          //   chipField = 'process_id';
          //   odfb.contains(`tolower(${chipField})`, element.searchWord.toLowerCase());
          // }
          else if (chipField.toString().toLowerCase() == 'gross_amount' || chipField.toString().toLowerCase() == 'net_amount') {
            odfb.contains(`tolower(cast(${chipField},'Edm.String'))`, element.searchWord.toLowerCase());
          }
          else {
            odfb.contains(`tolower(${chipField})`, element.searchWord.toLowerCase());
          }
        }
      });
    }
    
    searchParams.filter = searchParams.filter?.replace(',', '.'); // with `,` (comma) get parsing Odata error on backend
    console.log('---------------get ChangeVouchers--------------------  >>>:searchParams.dataOption   ', searchParams.dataOption, '  searchParams.filter  ', searchParams.filter );
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.changeVoucherOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    console.log('---------------get ChangeVouchers--------------------  >>>:searchData   ', searchData, '  url ', url);
    logger.log(`getChangeVouchers${url}`);

    return instance.put<Value>(url, searchData);
  },

  deleteChangeVoucher(id: string): AxiosPromise {
    logger.debug('deleteChangeVoucher');
    return instance.delete(`${URLS.changeVoucher}/${id}`);
  },
  updateChangeVoucher(ChangeVoucher: ChangeVoucher): AxiosPromise<any> {
    logger.debug('updateChangeVoucher');
    return instance.put<ChangeVoucher>(`${URLS.changeVoucher}/update`, changeVoucher.toAPI(ChangeVoucher));
  },
  creaateChangeVoucher(ChangeVoucher: ChangeVoucher): AxiosPromise<any> {
    logger.debug('---------------------creaateChangeVoucher');
    return instance.put<ChangeVoucher>(`${URLS.changeVoucher}/add`, changeVoucher.toAPI(ChangeVoucher));
  },
  getChangeVoucherAeaTyps(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.changeVoucher}/GetDistinctAeaTyps`;    
    return instance.put<Value>(url, searchData);
  },
  getChangeVoucherMinMaxBruttoNetto(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.changeVoucher}/GetMinMaxBruttoNetto`;
    return instance.put<Value>(url, searchData);
  },
  getChangeVoucherStatusTypes(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.changeVoucher}/GetDistinctStatusTypes`;    
    return instance.put<Value>(url, searchData);
  },
  getChangeVoucherIntStatusTypes(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.changeVoucher}/GetDistinctIntStatusTypes`;    
    return instance.put<Value>(url, searchData);
  },
  getChangeVoucherMembers(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.changeVoucher}/GetDistinctMembers`;    
    return instance.put<Value>(url, searchData);
  },
  getChangeVoucherSuppliers(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.changeVoucher}/GetDistinctSuppliers`;    
    return instance.put<Value>(url, searchData);
  },
  getChangeVouchersExcelReport(data: any, searchParams: SearchParams): AxiosPromise<any> {
    const payload = JSON.parse(JSON.stringify(searchParams)); // deep copy otherwise `searchParams` state changed and extra request to backend happened
    payload.dataOption.itemsPerPage = 10000;
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.changeVoucher}/GetChangeVouchersExcelReport`,
      payload.dataOption,
      payload.orClauseFieldsIds,
      payload.filter
    );
    logger.debug(`GetInvoiceExcelReport${url}`);
    return instance.put<any>(url, data, {
      responseType: 'blob',
    });
  },
  getChangeVouchersPdfReport(data: any, searchParams: SearchParams): AxiosPromise<any> {
    const payload = JSON.parse(JSON.stringify(searchParams)); // deep copy otherwise `searchParams` state changed and extra request to backend happened
    payload.dataOption.itemsPerPage = 5000;
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.changeVoucher}/GetChangeVouchersPdfReport`,
      payload.dataOption,
      payload.orClauseFieldsIds,
      payload.filter
    );
    logger.debug(`GetChangeVouchersPdfReport${url}`);
    return instance.put<any>(url, data, {
      responseType: 'blob',
    });
  },  
  getChangeVoucherFacetSearch(facetData: ChangeVoucherFacetSearchData): AxiosPromise<any> {
    let url = `${URLS.changeVoucher}/GetSearchResult`;    
    return instance.put<Value>(url, facetData);
  },
  getChangeVoucherDetailsPdfReport(data: any): AxiosPromise<any> {
    return instance.put<any>(`${URLS.changeVoucher}/GetChangeVoucherDetailsPdfReport`, data, {
      responseType: 'blob',
    });
  },
};
