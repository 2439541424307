export interface InvoiceFacetSearchData extends InvoiceSearchData {
  searchWord: string;
  chipDataString: string;

  // need all fields from SearchData for proper filtering and correct count numbers
}

export interface InvoiceSearchData {
  documentType: string[]; // belegtyp
  receiverNumbers: string[]; // mitglied
  senderNumbers: string[]; // lieferant
  datum: any; // date
  dueDate: any; // date
  endOfWeekDate: any; // date
  bruttoNetto?: any;
  isUserInAzureAdminGroup?: boolean; // `vme-admin` or `vme-support`(see all but cannot change) groups
  zrNummer?: string;
  chipDataString?: string;
  triggerChange?: boolean; // (GSP-116) need this artificial field to call backend when change bruto/netto in popup menu (othewise result somehow cached by reference and backend for new Netto/Brutto not called, since check object changes to avoid extra queries)
}

function defaultData(): InvoiceSearchData {
  return {
    documentType: [],
    receiverNumbers: [],
    senderNumbers: [],
    datum: undefined,
    dueDate: undefined,
    endOfWeekDate: undefined,
    bruttoNetto: {},
    isUserInAzureAdminGroup: undefined,

    triggerChange: false,
  };
}

export default {
  defaultData,
};
