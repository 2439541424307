import { ActionTree } from 'vuex';
import { SaveListState } from './types';
import { RootState } from '../../types';
import { defaultBackendSaveList } from '@/shared/backend/saveList';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import saveList, { Value, SaveList } from '@/shared/model/saveList';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.saveLists');
export const actions: ActionTree<SaveListState, RootState> = {
  getSaveLists({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setSaveListsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getSaveListsSearchParams;
    return defaultBackendSaveList
      .getSaveLists(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setSaveLists', response.data);
        commit('setSaveListsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setSaveListsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getSaveList({ commit, dispatch }, id: string) {
    return defaultBackendSaveList
      .getSaveList(id)
      .then((response: AxiosResponse<SaveList>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateSaveList({ commit, dispatch }, file: SaveList) {
    return defaultBackendSaveList
      .updateSaveList(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: SaveList;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.saveList_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteSaveList({ commit, dispatch }, id: string) {
    return defaultBackendSaveList
      .deleteSaveList(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: SaveList;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
