import { Component, Vue } from 'vue-property-decorator';
import ZrDocuments from '@/views/home/zr-documents/zr-documents.vue'; // @ is an alias to /src
import GeneralUtils from '@/shared/utils/generalUtils';
import { namespace } from 'vuex-class';

const authModule = namespace('auth');

@Component({
  components: {
    ZrDocuments,
  },
})
export default class Home extends Vue {
  @authModule.Getter('zrNummer')
  private zrNummer!: any;

  mounted() {
    // if (!GeneralUtils.isDevEnvironment()) {
    //   let hubspotScript = document.createElement('script');
    //   hubspotScript.setAttribute('id', 'hs-script-loader');
    //   hubspotScript.setAttribute('src', 'https://js.hs-scripts.com/14524674.js');
    //   document.head.appendChild(hubspotScript);
    // } else {
    //   console.log('THIS IS DEV ENV');
    // }
  }

  get isZrNummerReturned(){
    return !!this.zrNummer || this.zrNummer === null; // if was error on backend of `Admin-panel` then `zrNummer = NULL`
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }
}
