export const getDefault: DataOptions = {
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
  filter: '',
};

export interface DataOptions {
  page: number;
  itemsPerPage: number;
  sortBy: any[];
  sortDesc: any[] | boolean;
  groupBy: any[];
  groupDesc: any[];
  multiSort: boolean;
  mustSort: boolean;
  filter?: string;
}
export default {
  getDefault: getDefault,
};
