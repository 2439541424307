
import { GetterTree } from 'vuex';
import { ChangeVoucherNoticeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ChangeVoucherNoticeState, RootState> = {
  ['getChangeVoucherNotices']: (state) => state.changeVoucherNotices,
  ['getChangeVoucherNoticesIsLoading']: (state) => state.changeVoucherNotices?.isLoading,
  ['getChangeVoucherNoticesTotal']: (state) => state.changeVoucherNotices?.total,
  ['getChangeVoucherNoticesSearchParams']: (state) => state.changeVoucherNotices?.searchParams,
};
