import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import changeVoucherDocument, { ChangeVoucherDocument, Value } from '../model/changeVoucherDocument';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.ChangeVoucherDocument');
export interface BackendChangeVoucherDocument {
  getChangeVoucherDocument(id: string): AxiosPromise<ChangeVoucherDocument>;
  getChangeVoucherDocuments: (searchParams: SearchParams, changeVoucherId: number) => AxiosPromise<Value>;
  deleteChangeVoucherDocument(id: string): AxiosPromise;
  updateChangeVoucherDocument(ChangeVoucherDocument: ChangeVoucherDocument): AxiosPromise<any>;

  uploadDocument(registerPayload: any): AxiosPromise<any>;
  downloadDocument(registerPayload: any): AxiosPromise<any>;
  deleteDocument(id: string): AxiosPromise;
}

export const defaultBackendChangeVoucherDocument: BackendChangeVoucherDocument = {
  getChangeVoucherDocument(id: string): AxiosPromise<ChangeVoucherDocument> {
    let url = `${URLS.changeVoucherDocumentOdata}/${id}`;
    return instance.get<ChangeVoucherDocument>(url);
  },
  getChangeVoucherDocuments(searchParams: SearchParams, changeVoucherId: number): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (changeVoucherId>0) {
      odfb.eq('change_voucher_id', changeVoucherId, false);
    }
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.changeVoucherDocumentOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getChangeVoucherDocuments${url}`);
    return instance.get<Value>(url);
  },

  deleteChangeVoucherDocument(id: string): AxiosPromise {
    logger.debug('deleteChangeVoucherDocument');
    return instance.delete(`${URLS.changeVoucherDocument}/${id}`);
  },
  updateChangeVoucherDocument(ChangeVoucherDocument: ChangeVoucherDocument): AxiosPromise<any> {
    logger.debug('updateChangeVoucherDocument');
    return instance.put<ChangeVoucherDocument>(`${URLS.changeVoucherDocument}/update`, changeVoucherDocument.toAPI(ChangeVoucherDocument));
  },

  uploadDocument(registerPayload: any): AxiosPromise<any> {
    console.log('POST POST registerPayload :>> ', registerPayload);
    return instance.post<any>(`${URLS.changeVoucherDocument}/postDocument`, registerPayload); // 
  },
  downloadDocument(id: any): AxiosPromise<any> {
    return instance.get<any>(`${URLS.changeVoucherDocument}/download/${id}`, {
      responseType: 'blob',
    });
  },
  deleteDocument(id: string): AxiosPromise {
    logger.debug('deleteDocument');
    return instance.delete(`${URLS.changeVoucherDocument}/${id}`);
  },
};
