import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('supplier-tooltip');


@Component({
  components: {  },
})
export default class SupplierTooltip extends Vue {
  @Prop({ default: () => {} })
  private tooltipInfo!: any;

  private DateUtils = DateUtils;

  // PREVIOUS IMPLEMENTATION via methods
  // getTooltipInfo(zrNummer: any) {
  //   let tooltipInfo = this.supplierTooltipInfo.find((x: any) => x.zrNummer == zrNummer);
  //   console.log('tooltipInfo :>> ', tooltipInfo);
  //   let resutl = '';
  //   if (tooltipInfo) {
  //     resutl = `Status: ${tooltipInfo.neuerZRStatus} <br>
  //     ZR-Nummer: ${tooltipInfo.zrNummer} <br>
  //     ${tooltipInfo.zrEnde && !tooltipInfo.zrEnde.includes('2099') ? 'ZR Ende: ' + DateUtils.isoDateStringToDots(tooltipInfo.zrEnde) + '<br>' : ''}
  //     ${tooltipInfo.suchname2 ? 'Suchname 2: ' + tooltipInfo.suchname2 + '<br>' : ''}
  //     ${
  //       tooltipInfo.unternehmensnameZUSATZ
  //         ? 'Zusatz: ' + tooltipInfo.unternehmensnameZUSATZ + '<br>'
  //         : ''
  //     }
  //     ${
  //       tooltipInfo.zrBemerkungenzuKreditor_EXTERN
  //         ? 'ZR Bemerkung zu Kreditor (extern): ' + tooltipInfo.zrBemerkungenzuKreditor_EXTERN + '<br>'
  //         : ''
  //     }  `;
  //   }
  //   return resutl;
  // }
}
