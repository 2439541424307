
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import changeVoucherNotice, { ChangeVoucherNotice, Value } from '../model/changeVoucherNotice';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.ChangeVoucherNotice');
export interface BackendChangeVoucherNotice {
  getChangeVoucherNotice(id: string): AxiosPromise<ChangeVoucherNotice>;
  getChangeVoucherNotices: (searchParams: SearchParams, changeVoucherId: number) => AxiosPromise<Value>;
  deleteChangeVoucherNotice(id: string): AxiosPromise;
  updateChangeVoucherNotice(ChangeVoucherNotice: ChangeVoucherNotice): AxiosPromise<any>;
}

export const defaultBackendChangeVoucherNotice: BackendChangeVoucherNotice = {
  getChangeVoucherNotice(id: string): AxiosPromise<ChangeVoucherNotice> {
    let url = `${URLS.changeVoucherNoticeOdata}/${id}`;
    return instance.get<ChangeVoucherNotice>(url);
  },
  getChangeVoucherNotices(searchParams: SearchParams, changeVoucherId: number): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (changeVoucherId>0) {
      odfb.eq('change_voucher_id', changeVoucherId, false);
    }
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.changeVoucherNoticeOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getChangeVoucherNotices${url}`);
    return instance.get<Value>(url);
  },

  deleteChangeVoucherNotice(id: string): AxiosPromise {
    logger.debug('deleteChangeVoucherNotice');
    return instance.delete(`${URLS.changeVoucherNotice}/${id}`);
  },
  updateChangeVoucherNotice(ChangeVoucherNotice: ChangeVoucherNotice): AxiosPromise<any> {
    logger.debug('updateChangeVoucherNotice');
    return instance.put<ChangeVoucherNotice>(`${URLS.changeVoucherNotice}/update`, changeVoucherNotice.toAPI(ChangeVoucherNotice));
  },
};
